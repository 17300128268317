<template>
  <div style="padding-top: 10px">
    <div v-loading="loading"
         :element-loading-text="loadingMsg"
         element-loading-background="rgba(0, 0, 0, 0.8)"
         element-loading-spinner="el-icon-loading"
         style="max-width: 100%; margin: 10px auto;background: #fff;border-radius: 14px;min-height: 87vh;padding: 10px"
    >
      <div style="display: flex;justify-content: flex-start;align-items: center;">
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-input v-model="params.phone" placeholder="请输入需要查询的收货手机号"
                    size="mini" style="width: 200px"></el-input>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-input v-model="params.nickName" placeholder="请输入需要查询的收件人"
                    size="mini" style="width: 200px"></el-input>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-select v-model="params.status" clearable placeholder="请选择支付状态" size="mini" @change="getOrderList">
            <el-option label="全部" value=""></el-option>
            <el-option label="未支付" value="0"></el-option>
            <el-option label="已支付" value="1"></el-option>
            <el-option label="退款" value="2"></el-option>
          </el-select>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-select v-model="orderStatus" multiple clearable placeholder="请选择订单状态" size="mini"
                     @change="getOrderList">
            <!--订单状态-2 部分取消 -1 已取消 0 未提交京东(失败) 1 提交京东待发货 2 已发货 3 已妥投 4 部分妥投 5 已完成 6 部分完成 7 部分发货-->
            <el-option label="全部" value=""></el-option>
            <el-option label="部分取消" value="-2"></el-option>
            <el-option label="已取消" value="-1"></el-option>
            <el-option label="未提交京东(失败)" value="0"></el-option>
            <el-option label="提交京东待发货" value="1"></el-option>
            <el-option label="已发货" value="2"></el-option>
            <el-option label="已妥投" value="3"></el-option>
            <el-option label="部分妥投" value="4"></el-option>
            <el-option label="已完成" value="5"></el-option>
            <el-option label="部分完成" value="6"></el-option>
            <el-option label="部分发货" value="7"></el-option>
          </el-select>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-date-picker
              v-model="value2"
              :picker-options="pickerOptions"
              align="right"
              end-placeholder="结束下单日期"
              range-separator="至"
              size="mini"
              start-placeholder="开始下单日期"
              type="datetimerange">
          </el-date-picker>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-select v-model="params.provinceId" placeholder="请选择省" size="mini" @change="provinceChange">
            <el-option v-for="(item,index) in addressList.provinceList" :key="index" :label="item.name"
                       :value="`${item.kid}`"></el-option>
          </el-select>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-button size="mini" type="primary" @click="getOrderList">搜索</el-button>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-button size="mini" @click="resetListParams">重置</el-button>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-button size="mini" @click="exportOrder">导出订单</el-button>
        </div>
      </div>
      <div style="display: flex;justify-content: flex-start;align-items: center;">
        <div style="margin-left: 10px;margin-bottom: 10px">
          消费卡批次号：
          <el-input v-model="params.cardLogId" placeholder="请输入需要查询的消费卡批次号"
                    size="mini" style="width: 200px"></el-input>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          购买者ID：
          <el-input v-model="params.userId" placeholder="购买者ID"
                    size="mini" style="width: 200px"></el-input>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          一级分类：
          <el-select v-model="params.categoryId1" size="mini" placeholder="请选择" style="width: 60%"
                     @change="getCategory1List">
            <el-option label="请选择" value="">
            </el-option>
            <el-option
                v-for="(item,index) in categoryId1"
                :key="index"
                :label="item.fullName"
                :value="item.categoryId">
            </el-option>
          </el-select>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          二级分类：
          <el-select v-model="params.categoryId2" size="mini" placeholder="请选择" style="width: 60%">
            <el-option label="请选择" value="">
            </el-option>
            <el-option
                v-for="(item,index) in categoryId2"
                :key="index"
                :label="item.fullName"
                :value="item.categoryId">
            </el-option>
          </el-select>
        </div>
      </div>
      <el-table
          :data="rows"
          :row-class-name="tableRowClassName"
          style="width: 100%">
        <!--        子列表 下拉列表 start-->
        <el-table-column type="expand">
          <template slot-scope="props">
            <div style="margin: 10px">
              <el-table
                  :data="props.row.list"
                  :row-class-name="tableRowClassName"
                  style="width: 100%;margin-bottom: 20px;">
                <el-table-column
                    label="JD订单号(子)"
                    prop="orderId"
                    width="120">
                </el-table-column>
                <el-table-column
                    label="支付状态"
                    width="110">
                  <template slot-scope="{row}">
                    {{ row.status | statusF }}
                  </template>
                </el-table-column>
                <el-table-column
                    label="订单状态"
                    width="110">
                  <template slot-scope="{row}">
                    {{ row.orderStatus | orderItemStatusF }}
                  </template>
                </el-table-column>
                <el-table-column
                    label="商品状态"
                    width="110">
                  <template slot-scope="{row}">
                    {{ row.validCode | validCodeF }}
                  </template>
                </el-table-column>
                <el-table-column
                    label="数量"
                    prop="skuNum"
                    width="80">
                </el-table-column>
                <el-table-column
                    label="SkuId"
                    prop="skuId"
                    width="120">
                </el-table-column>
                <el-table-column
                    label="商品名"
                    prop="skuName"
                    width="400">
                </el-table-column>
                <el-table-column
                    label="京东价"
                    width="100">
                  <template slot-scope="{row}">
                    {{ row.price | format }}
                  </template>
                </el-table-column>
                <el-table-column
                    label="采购价"
                    width="100">
                  <template slot-scope="{row}">
                    {{ row.purchasePrice | format }}
                  </template>
                </el-table-column>
                <el-table-column
                    label="喜豚宝销售价"
                    width="100">
                  <template slot-scope="{row}">
                    {{ row.originalPrice | format }}
                  </template>
                </el-table-column>
                <el-table-column
                    label="商品类型"
                    width="100">
                  <template slot-scope="{row}">
                    {{ row.goodsType | goodsTypeF }}
                  </template>
                </el-table-column>
                <el-table-column
                    label="喜豚宝订单号"
                    prop="mdlOrder"
                    width="200">
                </el-table-column>
                <el-table-column
                    label="操作">
                  <template slot-scope="{row}">
                    <el-link v-if="row.orderId" :underline="false" type="primary"
                             @click="getBackendDeliveryByJdOrderId(row)">查看物流
                    </el-link>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </template>
        </el-table-column>
        <!--        子列表 下拉列表end-->
        <el-table-column
            label="JD订单号(父)"
            prop="orderId"
            width="120">
        </el-table-column>
        <el-table-column
            label="购买者"
            prop="userId"
            width="65">
        </el-table-column>
        <el-table-column
            label="支付状态"
            width="80">
          <template slot-scope="{row}">
            {{ row.status | statusF }}
          </template>
        </el-table-column>
        <el-table-column
            label="订单状态"
            width="80">
          <template slot-scope="{row}">
            {{ row.orderStatus | orderStatusF }}
          </template>
        </el-table-column>
        <el-table-column
            label="喜豚宝售价(点 1:1)"
            width="100">
          <template slot-scope="{row}">
            {{ row.originalPrice | format }}
          </template>
        </el-table-column>
        <el-table-column
            label="采购价"
            width="100">
          <template slot-scope="{row}">
            {{ row.purchasePrice | format }}
          </template>
        </el-table-column>
        <el-table-column
            label="利润"
            width="80">
          <template slot-scope="{row}">
            {{ row.profit | format }}
          </template>
        </el-table-column>
        <el-table-column
            label="消耗余额"
            width="100">
          <template slot-scope="{row}">
            {{ row.useBalance | format }}
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            label="收货人"
            width="80">
          <template slot-scope="{row}">
            {{ row.addressList.realName }}
          </template>
        </el-table-column>
        <el-table-column
            label="收货手机号"
            width="120">
          <template slot-scope="{row}">
            {{ row.addressList.mobile }}
          </template>
        </el-table-column>
        <el-table-column
            label="收货地址"
            prop="provinceName" width="250">
          <template slot-scope="{row}">
            {{ row.addressList.provinces }} {{ row.addressList.city }} {{ row.addressList.county }}
            {{ row.addressList.street }}-{{ row.addressList.detailAddress }}
          </template>
        </el-table-column>

        <el-table-column
            label="支付时间"
            width="176">
          <template slot-scope="{row}">
            <div v-if="row.payTime">
              {{ `${row.payTime}000` | formatDate }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
            label="下单时间"
            width="176">
          <template slot-scope="{row}">
            {{ `${row.createTime}000` | formatDate }}
          </template>
        </el-table-column>
        <el-table-column
            label="返余额"
            width="80">
          <template slot-scope="{row}">
            {{ row.returnBalance | format }}
          </template>
        </el-table-column>
        <el-table-column
            label="支付金额"
            width="80">
          <template slot-scope="{row}">
            {{ row.payPrice | format }}
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            label="运费"
            prop="freight"
            width="80">
          <template slot-scope="{row}">
            {{ row.freight | format }}
          </template>
        </el-table-column>

        <el-table-column
            label="订单类型"
            width="110">
          <template slot-scope="{row}">
            {{ row.orderType | orderTypeF }}
          </template>
        </el-table-column>
        <el-table-column
            fixed
            label="ID"
            prop="id"
            width="50">
        </el-table-column>
        <el-table-column
            label="喜豚宝订单号"
            prop="mdlOrder"
            width="200">
        </el-table-column>

        <!--<el-table-column-->
        <!--    fixed="right"-->
        <!--    label="操作"-->
        <!--    width="200px">-->
        <!--</el-table-column>-->

      </el-table>
      <el-pagination
          :page-size="0"
          :page-sizes="[10, 20, 30, 50]"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          style="margin-top: 10px;text-align: center"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange">
      </el-pagination>
    </div>
    <el-dialog
        :visible.sync="deliveryDialog"
        title="物流"
        width="30%">
      <el-timeline :reverse="true">
        <el-timeline-item
            v-for="(activity, index) in deliveryList"
            :key="index"
            :timestamp="activity.trackMsgTime | parseTime">
          {{ activity.trackContent }}
        </el-timeline-item>
      </el-timeline>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deliveryDialog = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  exportXiTunBaoOrderApi,
  getXiTunBaoDeliveryByJdOrderIdApi,
  getXiTunBaoOrderApi
} from "@/api/backend/xitunbaoApi";
import sd from "silly-datetime";
import {findBackendJdRegionApi} from "@/api/backend/addressApi";
import {getCategory1ListApi, getCategoryListApi} from "@/api/admin/categoryApi";

export default {
  name: "XiTunBaoOrder",
  data() {

    return {
      value2: [],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      rows: [],
      size: 10,
      current: 1,
      total: 0,
      loading: false,
      loadingMsg: "列表加载中...",
      addressList: {
        provinceList: [],
        cityList: [],
        countyList: [],
        townList: []
      },
      categoryId1: [],
      categoryId2: [],
      params: {
        current: 1,
        size: 10,
        categoryId1: "",
        categoryId2: "",
        cardLogId: "",
        status: "",
        nickName: "",
        phone: "",
        startCreateTime: "",
        endCreateTime: "",
        provinces: "",
        city: "",
        area: "",
        street: "",
        orderStatus: [],
        userId: ""
      },
      orderStatus: [],
      deliveryList: [],
      deliveryDialog: false
    }
  },
  filters: {
    orderStatusF(v) {
      // 订单状态-2 部分取消 -1 已取消 0 未提交京东(失败) 1 提交京东待发货 2 已发货 3 已妥投 4 部分妥投 5 已完成 6 部分完成 7 部分发货
      switch (v) {
        case "-2":
          return '部分取消';
        case "-1":
          return '已取消';
        case "0":
          return '未提交京东';
        case "1":
          return '提交京东待发货';
        case "2":
          return '已发货';
        case "3":
          return '已妥投';
        case "4":
          return '部分妥投';
        case "5":
          return '已完成';
        case "6":
          return '部分完成';
        case "7":
          return '部分发货';
      }
    },
    orderItemStatusF(v) {
      // -1 已取消  1  '待发货' 2  '已发货' 3  '已妥投' 4 '已完成'
      switch (v) {
        case "-1":
          return '已取消';
        case "1":
          return '待发货'
        case "2":
          return '已发货'
        case "3":
          return '已妥投'
        case "4":
          return '已完成'
      }
    },
    validCodeF(value) {
      // 商品状态:3=未支付,4=退款,5=申请退款,15=已支付等待发货,16=已发货,17=确认收货
      switch (value) {
        case "3":
          return '未支付'
        case "4":
          return '退款'
        case "5":
          return '申请退款'
        case "15":
          return '已支付等待发货'
        case "16":
          return '已发货'
        case "17":
          return '确认收货'
      }
    },
    formatDate(value) {
      return sd.format(+value)
    },
    goodsTypeF(type) {
      switch (type) {
        case "1":
          return '普通商品'
        case "2":
          return '限时秒杀'
        case "3":
          return '送余额商品'
        case "4":
          return '一元购商品'
      }
    },
    statusF(type) {
      switch (type) {
        case "0":
          return '未支付'
        case "1":
          return '已支付'
        case "2":
          return '退款'
      }
    },
    orderTypeF(type) {
      // 1=普通商品订单,2=限时秒杀订单,3=送余额商品订单
      switch (type) {
        case "1":
          return '普通商品订单'
        case "2":
          return '限时秒杀订单'
        case "3":
          return '送余额商品订单'

      }
    }
  },
  methods: {
    /**
     * 初始化分类
     */
    async initCategory() {
      let {data} = await getCategoryListApi();
      this.categoryId1 = data.categoryList;
    },
    /**
     * 获取二级分类
     */
    async getCategory1List() {
      let {data} = await getCategory1ListApi({
        name: this.params.categoryId1, categoryLevel: 1
      });
      this.params.categoryId2 = ""
      this.categoryId2 = data.categoryList;
    },
    provinceChange(id) {
      this.findBackendJdRegion("cityList", id);
      this.params.cityId = ""
      this.params.countyId = ""
      this.params.townId = ""
      this.addressList.cityList = []
      this.addressList.countyList = []
      this.addressList.townList = []
      this.getOrderList()
    },
    cityChange(id) {
      this.findBackendJdRegion("countyList", id);
      this.params.countyId = ""
      this.params.townId = ""
      this.addressList.countyList = []
      this.addressList.townList = []
      this.getOrderList()
    },
    countyChange(id) {
      this.findBackendJdRegion("townList", id);
      this.params.townId = ""
      this.addressList.townList = []
      this.getOrderList()
    },
    addressDialogOpen() {
      this.params.provinceId = localStorage.getItem("provinceId") || ""
      this.params.cityId = localStorage.getItem("cityId") || ""
      this.params.countyId = localStorage.getItem("countyId") || ""
      this.params.townId = localStorage.getItem("townId") || ""

      this.findBackendJdRegion("provinceList", 0);
      if (this.params.provinceId) {
        this.findBackendJdRegion("cityList", this.params.provinceId);
      }
      if (this.params.cityId) {
        this.findBackendJdRegion("countyList", this.params.cityId);
      }
      if (this.params.countyId) {
        this.findBackendJdRegion("townList", this.params.countyId);
      }
    },
    async findBackendJdRegion(type, id) {
      let {data} = await findBackendJdRegionApi({
        id
      })
      this.addressList[type] = data
    },
    resetListParams() {
      this.params = {
        current: 1,
        size: 10,
        status: "",
        nickName: "",
        phone: "",
        startCreateTime: "",
        endCreateTime: "",
        provinces: "",
        city: "",
        area: "",
        street: "",
        orderStatus: ""
      }
      this.getOrderList();
    },
    async exportOrder() {
      this.loading = true;
      this.loadingMsg = "文件下载中...";
      const response = await exportXiTunBaoOrderApi(this.params)
      const blob = new Blob([response], {type: 'application/octet-stream'});
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${sd.format(new Date())}.xlsx`; // 设置下载的文件名，根据需要修改
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      this.$message({
        message: '文件下载成功',
        type: 'success'
      });
      this.loading = false;
    },
    tableRowClassName({row}) {
      console.log(row)

    },
    async getOrderList() {
      if (this.orderStatus.length > 0 && this.orderStatus != null) {
        this.params.orderStatus = this.orderStatus.join(',');
      }
      this.loading = true;
      this.loadingMsg = "列表加载中...";
      this.params.startCreateTime = this.value2.length > 0 ? `${+this.value2[0]}`.slice(0, 10) : ""
      this.params.endCreateTime = this.value2.length > 0 ? `${+this.value2[1]}`.slice(0, 10) : ""
      let {data} = await getXiTunBaoOrderApi(this.params)
      this.rows = data.rows
      this.size = data.size
      this.current = data.current
      this.total = data.total
      this.loading = false;
    },
    async getBackendDeliveryByJdOrderId(item) {
      this.deliveryDialog = true;
      this.deliveryList = []
      let {data} = await getXiTunBaoDeliveryByJdOrderIdApi({
        orderId: item.orderId,
        skuId: item.skuId
      })
      this.deliveryList = data
    },
    /**
     * 分页大小切换时触发
     * @param val
     */
    handleSizeChange(val) {
      this.params.size = val;
      this.getOrderList();
    },
    /**
     * 页码切换时触发
     * @param val
     */
    handleCurrentChange(val) {
      this.params.current = val;
      this.getOrderList();
    },
  },
  created() {
    this.getOrderList()
    this.findBackendJdRegion("provinceList", 0);
    this.initCategory();
  }
}
</script>
<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>