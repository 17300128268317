import service from "@/utils/requestDownload";


/**
 * 按条件下载所有搜索VoP数据api
 */
export function downloadAllSearchVopDataByCondition(data) {
    return service({
        url: '/download/downloadAllCustom',
        method: 'post',
        data
    })
}