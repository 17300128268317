<template xmlns="http://www.w3.org/1999/html">
  <div>
    <el-row
        v-loading="loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        element-loading-spinner="el-icon-loading"
        element-loading-text="拼命加载中">
      <el-col :span="4">
        <div style="padding: 10px">
          <el-form id="topicGoodsForm" :model="selectionGoods" label-position="top" label-width="80px">
            <el-form-item v-if="item.staffId == 2 || item.staffId == 3" label="商品范围">
              <el-select filterable v-model="selectionGoods.fromTopic" placeholder="请选择" style="width: 100%">
                <el-option label="所有商品" value="">
                </el-option>
                <el-option label="独有商品" :value="1">
                </el-option>
                <el-option label="共有商品" :value="2">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="商品名称">
              <el-input v-model="selectionGoods.title" placeholder="请输入需要查询的商品名称"></el-input>
            </el-form-item>
            <el-form-item label="商品ID">
              <el-input v-model="selectionGoods.goodsId" placeholder="请输入需要查询的商品ID"></el-input>
            </el-form-item>
            <el-form-item v-if="false" label="商品货源">
              <el-radio-group v-model="selectionGoods.isJd" size="small" @input="isJdChange">
                <el-radio-button :label="1">京东货源</el-radio-button>
                <el-radio-button :label="2">自营货源</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="商品状态">
              <el-select v-model="selectionGoods.status" placeholder="请选择状态" style="width: 100%">
                <el-option label="请选择" value=""></el-option>
                <el-option label="上架" :value="1"></el-option>
                <el-option label="下架" :value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="一级类别">
              <el-select filterable v-model="selectionGoods.categoryId1Name" placeholder="请选择" style="width: 100%"
                         @change="getCategory1List">
                <el-option label="请选择" value="">
                </el-option>
                <el-option
                    v-for="(item,index) in categoryId1"
                    :key="index"
                    :label="item.fullName"
                    :value="item.categoryId">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="二级类别">
              <el-select v-model="selectionGoods.categoryId2Name" placeholder="请选择" style="width: 100%"
                         @change="queryGetList">
                <el-option label="请选择" value="">
                </el-option>
                <el-option
                    v-for="(item,index) in categoryId2"
                    :key="index"
                    :label="item.fullName"
                    :value="item.categoryId">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="全国分销价区间">
              <el-input v-model="selectionGoods.minSupplyPrice" placeholder="最小值" style="width: 45%;"></el-input>
              <span style="margin: 0 5px;">~</span>
              <el-input v-model="selectionGoods.maxSupplyPrice" placeholder="最大值" style="width: 45%;"></el-input>
            </el-form-item>
            <el-form-item label="折扣区间">
              <el-input v-model="selectionGoods.minProfitMargin" placeholder="最小值" style="width: 45%;"></el-input>
              <span style="margin: 0 5px;">~</span>
              <el-input v-model="selectionGoods.maxProfitMargin" placeholder="最大值" style="width: 45%;"></el-input>
            </el-form-item>
            <!--            <el-form-item label="评论数">
                          <el-select v-model="selectionGoods.commentType" placeholder="请选择" style="width: 100%"
                                     @change="queryGetList">
                            <el-option label="请选择" value="">
                            </el-option>
                            <el-option
                                v-for="item in commentType()"
                                :key="item.count"
                                :label="item.title"
                                :value="item.count">
                            </el-option>
                          </el-select>
                        </el-form-item>-->
            <el-form-item label="排序规则">
              <el-select v-model="selectionGoods.sortOrder" multiple clearable placeholder="请选择" style="width: 70%"
                         @change="queryGetList">
                <el-option label="默认" value=""></el-option>
                <el-option label="Sort升序" value="-1"></el-option>
                <el-option label="评论数降序" value="1"></el-option>
                <el-option label="评论数升序" value="2"></el-option>
                <el-option label="上下架降序(下架在前)" value="3"></el-option>
                <el-option label="入库时间降序" value="4"></el-option>
                <el-option label="入库时间升序" value="5"></el-option>
                <el-option label="成本价降序" value="6"></el-option>
                <el-option label="成本价升序" value="7"></el-option>
                <el-option label="加价利率降序" value="8"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="商品比率状态">
              划线比率：
              <el-input v-model="selectionGoods.scribingRatio" placeholder="划线比率" style="width: 30%;"></el-input>
              <br>
              <el-select v-model="selectionGoods.scribingType" placeholder="请选择配送方式" style="width: 100%">
                <el-option label="请选择" value=""></el-option>
                <el-option label="补贴" :value="1"></el-option>
                <el-option label="赚钱" :value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="分销价加价比率">
              <el-input v-model="selectionGoods.supplyRate" placeholder="分销价加价比率" style="width: 45%;"></el-input>
            </el-form-item>
            <el-form-item label="评论数最小值">
              <el-input v-model="selectionGoods.commentOkCount" placeholder="评论最小值" style="width: 45%;"></el-input>
            </el-form-item>
            <el-form-item label="配送方式">
              <el-select v-model="selectionGoods.logisticsType" placeholder="请选择配送方式" style="width: 100%">
                <el-option label="全部" value=""></el-option>
                <el-option label="京东" :value="1"></el-option>
                <el-option label="其他" :value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item style="margin-top: 20px">
              <el-button type="primary" @click="queryGetList">筛选</el-button>
              <el-button @click="reset">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-col>
      <el-col :span="20">
        <div style="padding: 10px;">
          <div style="display: flex;justify-content: space-between;width:100%;align-items: center;">
            <div style="width: 15%">
              <h3 class="card-title align-items-start flex-column">
              <span class="card-label fw-bolder fs-3 mb-1">
                已选商品库列表
              </span>
                <br/>
                <span class="text-muted mt-1 fw-bold fs-7 ql-color-green">
                  绿色代表：<span style="color: green">商品下架或者价格不符</span> <br/>
                  红色代表：<span style="color: red">本库与子库共有商品 </span>
                </span>
              </h3>
            </div>
            <div class="ms-10">

              <el-button :plain="index!==0" size="small" type="primary" @click="switchGoods(0)">可选商品</el-button>
              <el-button :plain="index!==1" size="small" type="primary" @click="switchGoods(1)">已选商品</el-button>
              <el-button :disabled="idArr.length===0" size="small" type="danger" @click="batchDelGoods">批量删除
              </el-button>
              <!--              <el-button size="small" type="danger" @click="clearSelGoods">清空选品库</el-button>-->
<!--              <el-button size="small" type="primary" @click="addressDialog=true">设置收获地址</el-button>-->
              <el-button v-loading="excelLoading"
                         element-loading-background="rgba(0, 0, 0, 0.8)"
                         element-loading-spinner="el-icon-loading" size="small" type="success" @click="exportExcel">导出文件
              </el-button>
              <el-button @click="showAddressDialog">设置收货地址</el-button>
              <!--              <el-button element-loading-background="rgba(0, 0, 0, 0.8)"-->
              <!--                         element-loading-spinner="el-icon-loading" size="small" type="success" @click="openLink">-->
              <!--                打开此列商品京东链接-->
              <!--              </el-button>-->
              <label style="font-weight: bold; margin-left: 5px; font-size: 16px;">京东价溢价比率：</label>
              <el-input v-model="jdRate" size="small" style="width: 6%" @blur="editRate"></el-input>
              <label style="font-weight: bold; margin-left: 5px; font-size: 16px;">分销价加价比率(*)：</label>
              <el-input v-model="rate" size="small" style="width: 6%" @blur="editRate"></el-input>
              <label style="font-weight: bold; margin-left: 5px; font-size: 16px;">后台加价比率(/)：</label>
              <el-input v-model="systemRate" size="small" style="width: 6%"></el-input>
            </div>
          </div>
          <el-table
              :data="selectGoods"
              style="width: 100%"
              @selection-change="handleSelectionChange"
              :row-class-name="tableRowClassName"
          >
            <el-table-column
                type="selection"
                width="30">
            </el-table-column>
            <el-table-column label="商品信息">
              <template slot-scope="scope">
                <div v-if="scope.row.picUrl.indexOf('/Uploads') === 0">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-50px me-5">
                      <img :src="`https://www.1c-1g.cn${scope.row.picUrl}`" alt="">
                    </div>
                    <div class="d-flex justify-content-start flex-column">
                      <el-tooltip :content="scope.row.title" placement="top">
                        <a :href="scope.row.jdUrl"
                           class="text-dark fw-bolder text-hover-primary mb-1 fs-6 title-ellipsis"
                           target="_blank" @click="openSearchTab(scope.row.title)"
                        >{{ scope.row.title }}</a>
                      </el-tooltip>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-50px me-5">
                      <img :src="scope.row.picUrl" alt="">
                    </div>
                    <div class="d-flex justify-content-start flex-column">
                      <el-tooltip :content="scope.row.title" placement="top">
                        <a :href="`${scope.row.jdUrl}`"
                           class="text-dark fw-bolder text-hover-primary mb-1 fs-6 title-ellipsis"
                           target="_blank" @click="openSearchTab(scope.row.title)"
                        >{{ scope.row.title }}</a>
                      </el-tooltip>
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="分类" width="90px">
              <template slot-scope="scope">
                <el-tooltip
                    class="item"
                    effect="dark"
                    :content="'入库时间' + String(scope.row.createTime)"
                    placement="top">
                  <span class="text-muted fw-bold text-muted d-block fs-7">
                    {{ scope.row.categoryId1Name ? scope.row.categoryId1Name : "" }}
                    <br>
                    {{ scope.row.categoryId2Name ? scope.row.categoryId2Name : "" }}</span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
                label="商品来源" width="50">
              <template slot-scope="scope">
                <el-tooltip
                    class="item"
                    effect="dark"
                    :content="String(scope.row.skuId)"
                    placement="top">
                  <span>{{ scope.row.isJd == 1 ? "京东" : "自营" }}</span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="市场价" width="100">
              <template slot-scope="scope">
                <div class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">
                  {{ scope.row.price | format }}
                </div>
                加价：
                <div class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">
                  {{ scope.row.price * jdRate | format }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="分销价" width="100">
              <template slot-scope="scope">
                <div class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
                     @click="editDisPrice(scope.row)">
                  {{ scope.row.supplPrice | format }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="喜豚宝销售价" width="100">
              <template slot-scope="scope">
                <!--                <el-tooltip
                                    effect="dark"
                                    content="默认加价比例 1.25"
                                    placement="top">
                                  <i class="el-icon-info" style="margin-left: 5px;"></i> &lt;!&ndash; 使用图标提示 &ndash;&gt;
                                </el-tooltip>-->
                <span class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">
                  {{ scope.row.supplPrice * rate | format }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
                label="折扣"
                prop="price" width="100">
              <template slot-scope="scope">
                <div v-if="scope.row.isJd==1">
                  <a v-if="scope.row.supplPrice"
                     class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">{{
                      (scope.row.discount
                          * 10).toFixed(1)
                    }}折</a>
                  <a v-else>-</a>
                </div>
                <div v-else>
                  <a v-if="scope.row.supplPrice"
                     class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">{{
                      (((scope.row.poolDisPrice)
                          / scope.row.price) * 10).toFixed(1)
                    }}折</a>
                  <a v-else>-</a>
                </div>
              </template>
            </el-table-column>
            <el-table-column
                label="加价利率"
                prop="price" width="100">
              <template slot-scope="scope">
                <el-input v-model="scope.row.disPrice" size="small"></el-input>
                <div v-if="Number(scope.row.disPrice) && Number(scope.row.disPrice) !== 0 && scope.row.disPrice > selectionGoods.scribingRatio">
                  <span style="color: black">补贴价：</span><br/>
                  {{ (Number(scope.row.supplPrice) / Number(scope.row.disPrice)).toFixed(2) }}
                  <br/>
                  <span style="color: black">补:</span>
                    {{Math.abs(((Number(scope.row.supplPrice) / Number(scope.row.disPrice)) - (scope.row.supplPrice / selectionGoods.scribingRatio))).toFixed(2) }} 元 <br/>
                  <span style="color: black">亏:</span>
                  {{
                    Math.abs((((Number(scope.row.supplPrice) / Number(scope.row.disPrice)) - (scope.row.supplPrice / selectionGoods.scribingRatio)) / (scope.row.supplPrice / selectionGoods.scribingRatio)) * 100).toFixed(2)
                  }}%
                </div>
                <div v-if="Number(scope.row.disPrice) && Number(scope.row.disPrice) !== 0 && scope.row.disPrice <= selectionGoods.scribingRatio">
                  <span style="color: black">最终价：</span><br/>
                  {{ (Number(scope.row.supplPrice) / Number(scope.row.disPrice)).toFixed(2) }}
                  <br/>
                  <span style="color: black">多赚:</span>
                  {{ Math.abs(((Number(scope.row.supplPrice) / Number(scope.row.disPrice)).toFixed(2) - (scope.row.supplPrice / selectionGoods.scribingRatio).toFixed(2)).toFixed(2)) }} 元 <br/>
                  <span style="color: black">赚:</span>
                  {{
                    Math.abs((((Number(scope.row.supplPrice) / Number(scope.row.disPrice)) - (scope.row.supplPrice / selectionGoods.scribingRatio)) / (scope.row.supplPrice / selectionGoods.scribingRatio)) * 100).toFixed(2)
                  }}%
                </div>
              </template>
            </el-table-column>
            <el-table-column
                label="销售方式"
                prop="price" width="100">
              <template slot-scope="scope">
                <span v-if="scope.row.lowestBuy == 1 && scope.row.isJd == 1"
                      class="text-muted fw-bold text-muted d-block fs-7">一件代发</span>
                <span v-else class="text-muted fw-bold text-muted d-block fs-7">
                    集采({{ scope.row.lowestBuy }}个起售)
                  </span>
              </template>
            </el-table-column>
            <!--<el-table-column label="评论" width="100">-->
            <!--  <template slot-scope="scope">-->
            <!--      <span class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">-->
            <!--        {{ scope.row.commentDefaultCount }}-->
            <!--      </span>-->
            <!--  </template>-->
            <!--</el-table-column>-->
            <el-table-column label="排序" width="70">
              <template slot-scope="scope">
                  <span class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">
                    {{ scope.row.sort }}
                  </span>
              </template>
            </el-table-column>
            <el-table-column label="发货状态" width="100">
              <template slot-scope="scope">
                  <span class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">
                    {{ !scope.row.addressStatus ? "可发货" : "不可发货" }}
                  </span>
              </template>
            </el-table-column>
            <el-table-column label="状态" width="70">
              <template slot-scope="scope">
                  <span class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">
                    {{ scope.row.status != 2 ? "上架" : "下架" }}
                  </span>
              </template>
            </el-table-column>
            <el-table-column label="评论" width="90">
              <template slot-scope="scope">
                  <span class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">
                    {{ scope.row.commentOkCount }}
                  </span>
              </template>
            </el-table-column>
            <el-table-column label="配送" >
              <template slot-scope="scope">
                  <span v-if="scope.row.logisticsType === 1" class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">
                    京东配送
                  </span>
                <span v-else class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">
                    其他配送
                  </span>
                <div v-if="scope.row.isDelivery" v-for="(status, addressKey) in scope.row.isDelivery" :key="addressKey">
                    <span v-if="status.canPurchase" class="fs-7">
                      {{ addressKey }} 可配送
                    </span>
                  <span v-else class="text-danger fs-7" >
                      {{ addressKey }} 不可配送
                    </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="100">
              <template slot-scope="scope">
                <!--                <div style="display: flex; flex-direction: column; gap: 10px;">
                                  <el-button size="mini" type="primary" @click="setSort(scope.row)" style="margin-left: 10px;">修改顺序</el-button>
                                  <el-button size="mini" type="primary" >查看价格趋势</el-button>
                                  <el-button size="mini" type="warning" @click="delGoods(scope.row,1)">删除当前库该商品</el-button>
                                  <el-button size="mini" type="danger" @click="delGoods(scope.row,2)">删除所有库该商品</el-button>
                                </div>-->
                <div>
                  <el-dropdown trigger="click">
                    <el-button size="mini" type="primary">
                      操作 <i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item @click.native="setSort(scope.row)" icon="el-icon-sort">修改顺序</el-dropdown-item>
                      <el-dropdown-item @click.native="setTopicGoodsDisPrice(scope.row)" icon="el-icon-sort">修改加价利率</el-dropdown-item>
                      <el-dropdown-item icon="el-icon-money" @click.native="viewPriceTrend(scope.row)">查看价格趋势</el-dropdown-item>
                      <el-dropdown-item @click.native="delGoods(scope.row, 1)" icon="el-icon-delete">删除当前库该商品</el-dropdown-item>
<!--                      <el-dropdown-item class="text-hover-dark bg-hover-danger" @click.native="delGoods(scope.row, 2)"
                                        icon="el-icon-delete">删除自己所有库该商品
                      </el-dropdown-item>-->
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
            :current-page.sync="selectionGoods.current"
            :page-size="selectionGoods.size"
            :page-sizes="[10, 30, 50,100,200]"
            :total="total"
            layout="total, sizes, prev, pager, next, jumper"
            style="margin-top: 10px;text-align: center"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange">
        </el-pagination>
      </el-col>
    </el-row>

    <EChartsDialog
        :visible.sync="chartDialogVisible"
        :chartData="chartData"
        @chartDialogVisibleFn="chartDialogVisibleFn"
    />

    <JdAddressListDelivery
        :selected-addresses="selectedAddresses"
        @update:addresses="handleAddressUpdate"
        append-to-body
        ref="jdAddressListDelivery"/>
  </div>
</template>
<script>
import {mixins} from "./mixins";
import EChartsDialog from "@/components/LineChart/index.vue";

export default {
  name: 'SelGoods',
  components: {EChartsDialog},
  mixins: [mixins],
}
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

.el-table .exit-row {
  background: #f9ebed;
}
</style>