<template>
  <div>

    <div class="post d-flex flex-column-fluid" id="kt_post">
      <div id="kt_content_container"
           style="margin-top:10px;max-width:100%;flex-direction:row!important;display:flex; flex-wrap: wrap;">
        <!-- 搜索开始 -->
        <div style="width: 100%; margin-bottom: 10px;">
          <form action="#" id="category_form1">
            <div class="card">
              <div class="card-body" style="padding: 1.2rem 1rem; display: flex;">

                <label class="el-form-item__label fw-bolder text-dark" style="width: 90px; padding-right: 7px;">商品名称：</label>
                <div class="demo-input-suffix">
                  <el-input
                      @change="getLowestBuyList"
                      placeholder="请输入名称"
                      prefix-icon="el-icon-search"
                      v-model.trim="lowestBuyDto.title"
                      clearable @clear="getLowestBuyList">
                  </el-input>
                </div>

                <label class="el-form-item__label fw-bolder text-dark" style="width: 90px; padding-right: 7px;">商品名称：</label>
                <div class="demo-input-suffix">
                  <el-input
                      @change="getLowestBuyList"
                      placeholder="请输入SkuId"
                      prefix-icon="el-icon-search"
                      v-model.trim="lowestBuyDto.title"
                      clearable @clear="getLowestBuyList">
                  </el-input>
                </div>

<!--                <label class="el-form-item__label fw-bolder text-dark" style="width: 90px; padding-right: 7px;">商品来源：</label>
                <div class="demo-input-suffix">
                  <el-select v-model.number="lowestBuyDto.isJd" clearable placeholder="请选择分类级别" size="small">
                    <el-option :value="1" label="京东商品"></el-option>
                    <el-option :value="2" label="厂供商品"></el-option>
                  </el-select>
                </div>-->

                <label class="el-form-item__label fw-bolder text-dark" style="width: 90px; padding-right: 7px;">起售数量：</label>
                <div class="demo-input-suffix">
                  <el-input
                      placeholder="请输入内容"
                      v-model.number="lowestBuyDto.lowestBuy"
                      clearable>
                  </el-input>
                </div>
                <el-button size="mini" style="margin-left: 10px;width: 4%;" type="primary"  @click="getLowestBuyList">搜索</el-button>
                <div style="margin-left: auto;width: 6%;">
                  加价:
                  <el-input size="small"  type="success" v-model="rate"></el-input>
                </div>
                <el-button size="mini" style="margin-left: auto;width: 6%;" type="success"  @click="open">选择商品</el-button>
              </div>
            </div>
          </form>
        </div>
        <!-- 列表开始 -->
        <div class="card mb-5 mb-xl-8" style="margin-left:3px;width:100%;"
             element-loading-text="拼命加载中"
             element-loading-spinner="el-icon-loading"
             element-loading-background="rgba(0, 0, 0, 0.8)"
             v-loading="loading">
          <div class="card-body py-3" style="padding-left: 15px;">
            <div class="table-responsive">
              <!-- 表格数据-->
              <el-table
                  :data="rows"
                  style="width: 100%"
                  @selection-change="handleSelectionChange">
                <el-table-column
                    type="selection"
                    width="55">
                </el-table-column>

                <el-table-column prop="skuId" label="商品SkuId" align="center" class="100">
                </el-table-column>
                <el-table-column label="商品信息">
                  <template slot-scope="scope">
                    <div v-if="scope.row.picUrl.indexOf('/Uploads') ==0">
                      <div class="d-flex align-items-center">
                        <div class="symbol symbol-50px me-5">
                          <img :src="`https://www.1c-1g.cn${scope.row.picUrl}`"
                               alt="" class="">
                        </div>
                        <div
                            class="d-flex justify-content-start flex-column">
                          <a :href="`${scope.row.jdUrl}`"
                             class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                             target="_blank">{{ scope.row.title }}</a>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div class="d-flex align-items-center">
                        <div class="symbol symbol-50px me-5">
                          <img :src="scope.row.picUrl">
                        </div>
                        <div
                            class="d-flex justify-content-start flex-column">
                          <a :href="`https://item.jd.com/${scope.row.jdUrl}.html`"
                             class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                             target="_blank">{{ scope.row.title }}</a>
                        </div>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="price" label="市场价" align="center" class="100">
                  <template slot-scope="scope">
                    {{ scope.row.price | format }}
                  </template>
                </el-table-column>
                <el-table-column prop="disPrice" label="成本价" align="center" >
                  <template slot-scope="scope">
                    {{ scope.row.disPrice | format }}
                  </template>
                </el-table-column>
                <el-table-column label="销售价" align="center" >
                  <template slot-scope="scope">
                    {{ (scope.row.disPrice * rate).toFixed(2) | format }}
                  </template>
                </el-table-column>
                <el-table-column label="起售量" align="center">
                  <template slot-scope="scope">
                    <span class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">
                    自定义起售量：
                    {{ scope.row.customLowestBuy }}
                  </span>
                    <span class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">
                    京东默认起售量：
                    {{ scope.row.jdLowestBuy }}
                  </span>
                  </template>
                </el-table-column>
                <el-table-column label="物流信息" align="center">
                  <template slot-scope="scope">
                    <span v-if="scope.row.logisticsType === 1" class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">
                    京东配送
                  </span>
                    <span v-else class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">
                    其他配送
                  </span>
                  </template>
                </el-table-column>
                <el-table-column label="状态" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.lowestBuyStatus == 1 ? '启用' : '失效' }}
                  </template>
                </el-table-column>
                <el-table-column label="评论数" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.commentOkCount }}
                  </template>
                </el-table-column>
                <el-table-column label="创建时间" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.createTime | parseTime }}
                  </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                  <template slot-scope="scope">
                    <div>
                      <el-dropdown trigger="click">
                        <el-button size="mini" type="primary">
                          操作 <i class="el-icon-arrow-down el-icon--right"></i>
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item @click.native="editLowestBuyDialog(scope.row)">
                            <svg t="1729491217832" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2373" width="16" height="16">
                              <path d="M862.709333 116.042667a32 32 0 1 1 45.248 45.248L455.445333 613.813333a32 32 0 1 1-45.258666-45.258666L862.709333 116.053333zM853.333333 448a32 32 0 0 1 64 0v352c0 64.8-52.533333 117.333333-117.333333 117.333333H224c-64.8 0-117.333333-52.533333-117.333333-117.333333V224c0-64.8 52.533333-117.333333 117.333333-117.333333h341.333333a32 32 0 0 1 0 64H224a53.333333 53.333333 0 0 0-53.333333 53.333333v576a53.333333 53.333333 0 0 0 53.333333 53.333333h576a53.333333 53.333333 0 0 0 53.333333-53.333333V448z" fill="#000000" p-id="2374"></path>
                            </svg>
                            编辑起售数量</el-dropdown-item>
                          <el-dropdown-item @click.native="editLowestBuyStatus(scope.row,2)" >
                            <svg t="1729491341902" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3482" width="16" height="16">
                              <path d="M607.897867 768.043004c-17.717453 0-31.994625-14.277171-31.994625-31.994625L575.903242 383.935495c0-17.717453 14.277171-31.994625 31.994625-31.994625s31.994625 14.277171 31.994625 31.994625l0 351.94087C639.892491 753.593818 625.61532 768.043004 607.897867 768.043004z" fill="#575B66" p-id="3483"></path><path d="M415.930119 768.043004c-17.717453 0-31.994625-14.277171-31.994625-31.994625L383.935495 383.935495c0-17.717453 14.277171-31.994625 31.994625-31.994625 17.717453 0 31.994625 14.277171 31.994625 31.994625l0 351.94087C447.924744 753.593818 433.647573 768.043004 415.930119 768.043004z" fill="#575B66" p-id="3484"></path><path d="M928.016126 223.962372l-159.973123 0L768.043004 159.973123c0-52.980346-42.659499-95.983874-95.295817-95.983874L351.94087 63.989249c-52.980346 0-95.983874 43.003528-95.983874 95.983874l0 63.989249-159.973123 0c-17.717453 0-31.994625 14.277171-31.994625 31.994625s14.277171 31.994625 31.994625 31.994625l832.032253 0c17.717453 0 31.994625-14.277171 31.994625-31.994625S945.73358 223.962372 928.016126 223.962372zM319.946246 159.973123c0-17.545439 14.449185-31.994625 31.994625-31.994625l320.806316 0c17.545439 0 31.306568 14.105157 31.306568 31.994625l0 63.989249L319.946246 223.962372 319.946246 159.973123 319.946246 159.973123z" fill="#575B66" p-id="3485"></path><path d="M736.048379 960.010751 288.123635 960.010751c-52.980346 0-95.983874-43.003528-95.983874-95.983874L192.139761 383.591466c0-17.717453 14.277171-31.994625 31.994625-31.994625s31.994625 14.277171 31.994625 31.994625l0 480.435411c0 17.717453 14.449185 31.994625 31.994625 31.994625l448.096758 0c17.717453 0 31.994625-14.277171 31.994625-31.994625L768.215018 384.795565c0-17.717453 14.277171-31.994625 31.994625-31.994625s31.994625 14.277171 31.994625 31.994625l0 479.231312C832.032253 916.835209 789.028725 960.010751 736.048379 960.010751z" fill="#575B66" p-id="3486"></path>
                            </svg>
                            弃用该数据</el-dropdown-item>
                          <el-dropdown-item @click.native="editLowestBuyStatus(scope.row,1)" icon="el-icon-circle-plus">启用该数据</el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                  ref="pagination"
                  style="margin-top: 10px;text-align: center"
                  @size-change="statusHandleSizeChange"
                  @current-change="statusHandleCurrentChange"
                  :current-page.sync="lowestBuyDto.current"
                  :page-sizes="[10, 20, 30, 50]"
                  :page-size="lowestBuyDto.size"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="totalCount">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

<!--    所有商品弹窗-->
    <el-dialog
        title="所有商品"
        :visible.sync="goodsDialog"
        top="50px"
        width="98%"
    >
      <el-row
          v-loading="goodsLoading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          element-loading-spinner="el-icon-loading"
          element-loading-text="请求推送中..请勿刷新网页">
        <el-col :span="4">
          <div style="padding: 10px">
            <el-form id="topicGoodsForm" :model="selectionGoods" label-position="top" label-width="80px">
              <el-form-item label="商品名称">
                <el-input v-model="selectionGoods.title" placeholder="请输入需要查询的商品名称"></el-input>
              </el-form-item>
              <el-form-item label="商品ID">
                <el-input v-model="selectionGoods.goodsId" placeholder="请输入需要查询的商品ID"></el-input>
              </el-form-item>
              <el-form-item label="一级类别">
                <el-select filterable v-model="selectionGoods.categoryId1Name" placeholder="请选择" style="width: 100%"
                           @change="getCategory1List">
                  <el-option label="请选择" value="">
                  </el-option>
                  <el-option
                      v-for="(item,index) in categoryId1"
                      :key="index"
                      :label="item.fullName"
                      :value="item.categoryId">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="二级类别">
                <el-select v-model="selectionGoods.categoryId2Name" placeholder="请选择" style="width: 100%"
                           @change="getList">
                  <el-option label="请选择" value="">
                  </el-option>
                  <el-option
                      v-for="(item,index) in categoryId2"
                      :key="index"
                      :label="item.fullName"
                      :value="item.categoryId">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="全国分销价区间">
                <el-input v-model="selectionGoods.minSupplyPrice" placeholder="最小值" style="width: 45%;"></el-input>
                <span style="margin: 0 5px;">~</span>
                <el-input v-model="selectionGoods.maxSupplyPrice" placeholder="最大值" style="width: 45%;"></el-input>
              </el-form-item>
              <el-form-item label="折扣区间">
                <el-input v-model="selectionGoods.minProfitMargin" placeholder="最小值" style="width: 45%;"></el-input>
                <span style="margin: 0 5px;">~</span>
                <el-input v-model="selectionGoods.maxProfitMargin" placeholder="最大值" style="width: 45%;"></el-input>
              </el-form-item>
              <el-form-item label="市场价排序">
                <el-select v-model="selectionGoods.priceSort" placeholder="请选择" style="width: 100%"
                           @change="getList">
                  <el-option label="默认" value=""></el-option>
                  <el-option label="正序" value="asc"></el-option>
                  <el-option label="倒序" value="desc"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="评论数最小值">
                <el-input v-model="selectionGoods.commentOkCount" placeholder="评论最小值" style="width: 45%;"></el-input>
              </el-form-item>
              <el-form-item label="配送方式">
                <el-select v-model="selectionGoods.logisticsType" placeholder="请选择配送方式" style="width: 100%">
                  <el-option label="全部" value=""></el-option>
                  <el-option label="京东" :value="1"></el-option>
                  <el-option label="其他" :value="0"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item style="margin-top: 20px">
                <el-button type="primary" @click="getList">筛选</el-button>
                <el-button @click="reset">重置</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
        <el-col :span="20">
          <div style="padding: 10px;">
            <div style="display: flex;justify-content: space-between;width:100%;align-items: center;">
              <div>
                <h3 class="card-title align-items-start flex-column">
              <span class="card-label fw-bolder fs-3 mb-1">
                商品列表
              </span>
                </h3>
              </div>
              <div>
                <el-button :disabled="itemArr.length===0" size="small" type="primary" @click="openAddLowestBuyDialog">批量设置限购数量</el-button>
              </div>
            </div>
            <el-table
                :data="selectGoods"
                @selection-change="handleSelectionSkuIdChange"
                style="width: 100%">
              <el-table-column
                  type="selection"
                  width="30">
              </el-table-column>
              <el-table-column label="商品SkuId" width="130px">
                <template slot-scope="scope">
                  {{ scope.row.skuId }}
                </template>
              </el-table-column>
              <el-table-column label="商品信息">
                <template slot-scope="scope">
                  <div v-if="scope.row.picUrl.indexOf('/Uploads') ==0">
                    <div class="d-flex align-items-center">
                      <div class="symbol symbol-50px me-5">
                        <img :src="`https://www.1c-1g.cn${scope.row.picUrl}`"
                             alt="" class="">
                      </div>
                      <div
                          class="d-flex justify-content-start flex-column">
                        <a :href="`${scope.row.jdUrl}`"
                           class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                           target="_blank">{{ scope.row.title }}</a>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="d-flex align-items-center">
                      <div class="symbol symbol-50px me-5">
                        <img :src="scope.row.picUrl">
                      </div>
                      <div
                          class="d-flex justify-content-start flex-column">
                        <a :href="`https://item.jd.com/${scope.row.jdUrl}.html`"
                           class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                           target="_blank">{{ scope.row.title }}</a>
                      </div>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="分类" width="120px">
                <template slot-scope="scope">
                  <span class="text-muted fw-bold text-muted d-block fs-7">
                    {{ scope.row.categoryId1Name ? scope.row.categoryId1Name : "" }}
                    <br>
                    {{ scope.row.categoryId2Name ? scope.row.categoryId2Name : "" }}</span>
                </template>
              </el-table-column>
              <el-table-column
                  label="商品来源" width="100">
                <template slot-scope="scope">
                  <el-tooltip
                      class="item"
                      effect="dark"
                      :content="String(scope.row.skuId)"
                      placement="top">
                    <span>{{ scope.row.isJd == 1 ? "京东" : "自营" }}</span>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column label="市场价" width="100">
                <template slot-scope="scope">
                  <div class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">
                    {{ scope.row.price | format }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="分销价" width="100">
                <template slot-scope="scope">
                  <div class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">
                    {{ scope.row.disPrice | format }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="喜豚宝销售价" width="100">
                <template slot-scope="scope">
                  <div class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">
                    {{ scope.row.disPrice * 1.3 | format }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                  label="折扣"
                  prop="price" width="100">
                <template slot-scope="scope">
                  <div v-if="scope.row.isJd==1">
                    <a v-if="scope.row.disPrice"
                       class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">{{
                        (scope.row.discount
                            * 10).toFixed(1)
                      }}折</a>
                    <a v-else>-</a>
                  </div>
                  <div v-else>
                    <a v-if="scope.row.disPrice"
                       class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">{{
                        (((scope.row.poolDisPrice)
                            / scope.row.price) * 10).toFixed(1)
                      }}折</a>
                    <a v-else>-</a>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                  label="销售方式"
                  prop="price" width="100">
                <template slot-scope="scope">
                  <span v-if="scope.row.lowestBuy == 1 && scope.row.isJd == 1"
                        class="text-muted fw-bold text-muted d-block fs-7">一件代发</span>
                  <span v-else class="text-muted fw-bold text-muted d-block fs-7">
                    集采({{ scope.row.lowestBuy }}个起售)
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="评论" width="100">
                <template slot-scope="scope">
                  <span class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">
                    {{ scope.row.commentOkCount }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="配送" width="100">
                <template slot-scope="scope">
                  <span v-if="scope.row.logisticsType === 1" class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">
                    京东配送
                  </span>
                  <span v-else class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">
                    其他配送
                  </span>
                </template>
              </el-table-column>

              <el-table-column label="操作" width="100">
                <template slot-scope="scope">
                  <div>
                    <el-dropdown trigger="click">
                      <el-button size="mini" type="primary">
                        操作 <i class="el-icon-arrow-down el-icon--right"></i>
                      </el-button>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item @click.native="openAddLowestBuyDialog(scope.row)" icon="el-icon-circle-plus">限制起售数量</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <el-pagination
              :page-size="selectionGoods.size"
              :page-sizes="[10, 30, 50,100,200]"
              :total="total"
              :current-page.sync="selectionGoods.current"
              layout="total, sizes, prev, pager, next, jumper"
              style="margin-top: 10px;text-align: center"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange">
          </el-pagination>
        </el-col>
      </el-row>
    </el-dialog>

<!--    添加起售量弹窗-->
    <el-dialog
        title="商品起售数量"
        :visible.sync="addLowestBuyDialog"
        top="20px"
        width="40%"
    >
      <!-- 遍历 addLowestBuyDtoList 数组 -->
      <div v-for="(item, index) in addLowestBuyDtoList" :key="index" style="margin-bottom: 15px;">
        <el-form :model="item">
          <el-row>
            <el-col :span="4">
              <el-form-item>
                <label class="fw-bold">商品SkuId：</label>
                <el-input v-model="item.skuId" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="20">
              <el-form-item class="ms-2">
                <label class="fw-bold">商品信息：</label>
                <div v-if="item.picUrl.indexOf('/Uploads') ==0">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-50px me-5">
                      <img :src="`https://www.1c-1g.cn${item.picUrl}`"
                           alt="" class="">
                    </div>
                    <div
                        class="d-flex justify-content-start flex-column">
                      <a :href="`${item.jdUrl}`"
                         class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                         target="_blank">{{ item.title }}</a>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-50px me-5">
                      <img :src="item.picUrl">
                    </div>
                    <div
                        class="d-flex justify-content-start flex-column">
                      <a :href="`https://item.jd.com/${item.jdUrl}.html`"
                         class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                         target="_blank">{{ item.title }}</a>
                    </div>
                  </div>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="6">
            <!-- 商品来源选择框 -->
            <el-form-item label="商品来源">
              <el-select disabled v-model="item.isJd" placeholder="请选择商品来源">
                <el-option :value="1" label="京东商品"></el-option>
                <el-option :value="2" label="厂供商品"></el-option>
              </el-select>
            </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="最低起售数量">
                <el-input disabled v-model.number="item.initialLowestBuy" placeholder=""></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="起售数量">
                <el-input v-model.number="item.lowestBuy" placeholder="请输入起售数量" @change="checkLowestBuy(item)"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <hr style="border: 1px solid #dcdcdc; margin-top: 20px; margin-bottom: 20px;">
      </div>

      <!-- 弹窗底部按钮 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="addLowestBuyDialog = false">取 消</el-button>
        <el-button type="primary" @click="addGoodsLowestBuy()">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>

import {mixins} from "@/views/IDC/components/GoodsLowestBuy/mixins";

export default {
  mixins: [mixins]
};
</script>
<!--组件样式-->
<style scoped>

/deep/ .el-input--small .el-input__inner {
  height: 40px;
}


.log-container {
  max-height: 400px;
  overflow-y: auto;
  background-color: #f5f5f5;
  padding: 10px;
  border: 1px solid #ddd;
}
</style>