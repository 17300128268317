import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import {formatMoney} from '@/utils/formatMoney';
import TitleCard from "@/components/TitleCard/index.vue";
import UploadFile from "@/components/UploadFile/index.vue";
import sd from "silly-datetime";
import JsEncrypt from 'jsencrypt'
import * as echarts from 'echarts';
import * as _ from "lodash";

Vue.prototype.$echarts = echarts;
Vue.prototype.$jsEncrypt = JsEncrypt
Vue.config.productionTip = false

// 路由前置守卫，显示进度条
// 在每次路由切换之前执行的函数
router.beforeEach((to, from, next) => {
  // 定义允许直接访问的路由列表
  let whitelist=["/login","/register","/404","/500"]
  // 检查当前路由是否在允许直接访问的路由列表中*6
  if (!whitelist.includes(to.fullPath)) {
    // 若不在列表中，则将当前路由的路径存储到本地存储中
    localStorage.setItem('redirectPath', to.fullPath);
  }
  // 启动进度条动画
  NProgress.start();
  // 继续执行路由切换
  next();
});
// 路由后置守卫，隐藏进度条
router.afterEach(() => {
  NProgress.done(); // 隐藏进度条
});
Vue.filter("format", function (value) {
  return formatMoney(value);
});
/**
 * 格式化时间
 */
Vue.filter('parseTime', function (value) {
    return sd.format(value);
})
Vue.use(ElementUI);
Vue.component('TitleCard', TitleCard);
Vue.component('UploadFile', UploadFile);
document.title = "一采一购运营管理系统";
Vue.mixin({
  data() {
    return {
      globalUserInfo: {}, // 全局用户信息
    };
  },
  created() {
    // 自动加载用户信息
    try {
      this.globalUserInfo = JSON.parse(localStorage.getItem('userInfo')) || {};
    } catch (error) {
      console.error('加载用户信息失败:', error);
      this.globalUserInfo = {}; // 设置默认值为空对象
    }
  },
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

