import service from "@/utils/requestAdmin";

/**
 * 获取一级分类
 * @returns {*}
 */
export function getCategoryListApi() {
    return service({
        url: '/category/getFirstLevel',
        method: 'get',
    })
}

/**
 * 获取二级分类
 */
export function getCategory1ListApi(params) {
    return service({
        url: '/category/getOther',
        method: 'get',
        params
    })
}

export function getCategoryListByTopicApi(params) {
    return service({
        url: '/category/getFirstLevelByTopicId',
        method: 'get',
        params
    })
}

export function getCategory1ListByTopicApi(params) {
    return service({
        url: '/category/getOtherByTopicId',
        method: 'get',
        params
    })
}

export function getCategoryListByTopicNoSelectApi(params) {
    return service({
        url: '/category/getFirstLevelByTopicIdNotSelected',
        method: 'get',
        params
    })
}

export function getOtherByTopicIdNotSelectedApi(params) {
    return service({
        url: '/category/getOtherByTopicIdNotSelected',
        method: 'get',
        params
    })
}
