<template>
  <div style="padding-top: 10px">
    <div v-loading="loading"
         :element-loading-text="loadingMsg"
         element-loading-background="rgba(0, 0, 0, 0.8)"
         element-loading-spinner="el-icon-loading"
         style="max-width: 100%; margin: 10px auto;background: #fff;border-radius: 14px;min-height: 87vh;padding: 10px"
    >
      <div style="display: flex;justify-content: flex-start;align-items: center;">
        使用者ID：
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-input v-model="params.useUserId" placeholder="请输入需要查询的使用者id"
                    size="mini" style="width: 200px"></el-input>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          消费卡备注：
          <el-input v-model="params.remark" placeholder="请输入需要查询的消费卡备注"
                    size="mini" style="width: 200px"></el-input>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-select v-model="params.cardId" placeholder="请选择面值" size="mini" @change="getCardList">
            <el-option v-for="(item,index) in cardNameList" :key="index" :label="item.cardName"
                       :value="`${item.id}`"></el-option>
          </el-select>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-select v-model="params.cardType" placeholder="请选择类型" size="mini" @change="getCardList">
            <el-option label="电子卡" value="1"></el-option>
            <el-option label="实体卡" value="2"></el-option>
          </el-select>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-date-picker
              v-model="value2"
              :picker-options="pickerOptions"
              align="right"
              end-placeholder="结束结束日期"
              range-separator="至"
              size="mini"
              start-placeholder="开始生成日期"
              type="datetimerange">
          </el-date-picker>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-button size="mini" type="primary" @click="getCardList">搜索</el-button>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-button size="mini" @click="resetListParams">重置</el-button>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-button size="mini" @click="exportCardList">导出</el-button>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-button size="mini" @click="createDialog=true">卡密生成</el-button>
        </div>
      </div>
      <el-table
          :data="rows"
          style="width: 100%">
        <el-table-column
            fixed
            label="ID"
            prop="id"
            width="50">
        </el-table-column>
        <el-table-column
            label="卡批次"
            width="200"
            prop="mdlOrder">
        </el-table-column>
        <el-table-column
            label="卡类型"
            width="120">
          <template slot-scope="{row}">
            {{ row.cardType | cardTypeF }}
          </template>
        </el-table-column>
        <el-table-column
            label="卡面值"
            prop="cardName"
            width="150">
        </el-table-column>
        <el-table-column
            label="数量"
            width="80"
            prop="num">
        </el-table-column>
        <el-table-column
            label="批次总面值"
            width="180">
          <template slot-scope="{row}">
            {{ row.num * row.cardFaceValue }}
          </template>
        </el-table-column>
        <el-table-column
            label="备注"
            prop="remark">
        </el-table-column>
        <el-table-column
            width="180"
            label="生成时间">
          <template slot-scope="{row}">
            {{ `${row.createTime}000` | formatDate }}
          </template>
        </el-table-column>
        <el-table-column
            fixed="right"
            label="操作"
            width="500px">
          <template slot-scope="{row}">
            <el-button size="mini" type="primary" @click="getXiTunBaoCardDetail(row)">查看卡密</el-button>
            <el-button size="mini" type="info" @click="editRemark(row)">修改备注</el-button>
            <el-button size="mini" type="danger" @click="deleteCard(row)">删除卡</el-button>
            <el-button size="mini" class="badge-success" @click="cardStatus = true; cardData = row">修改状态</el-button>
            <el-button size="mini" type="success" @click="getCardUSerBindMobile(row.mdlOrder,null)">查看预绑手机号</el-button>
          </template>
        </el-table-column>

      </el-table>
      <el-pagination
          :page-size="0"
          :page-sizes="[10, 20, 30, 50]"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          style="margin-top: 10px;text-align: center"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange">
      </el-pagination>
    </div>
    <el-dialog
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        width="96%"
        @opened="$refs.KeyList.initList(item)"
        top="50px"
    >
      <KeyList ref="KeyList"></KeyList>
    </el-dialog>
    <el-dialog
        :visible.sync="createDialog"
        title="生成卡密"
        top="50px"
        width="30%"
        @opened="initCreateDialog">
      <el-form ref="form" :model="createParams" :rules="rules" label-width="80px">
        <el-form-item label="是否开通" prop="isOpen">
          <el-tooltip class="item" effect="dark" content="选择‘是’表示卡片状态为待使用 能使用，选择‘否’表示卡片未开通不能使用" placement="top">
            <el-radio-group v-model="createParams.isOpen">
              <el-radio :label="0">否</el-radio>
              <el-radio :label="1">是</el-radio>
            </el-radio-group>
          </el-tooltip>
        </el-form-item>
        <el-form-item v-if="createParams.isOpen === 1" label="开通时效" prop="cardPic" style="height: 45px!important;">
          <el-tooltip class="item" effect="dark" content="开通需要填写数字，表示月份" placement="top">
            <el-input v-model="createParams.openDuration" size="mini"></el-input>
          </el-tooltip>
        </el-form-item>
        <el-form-item v-if="createParams.isOpen === 1" label="到期时间" prop="cardPic">
          <el-input :disabled="true" v-model="createParams.expiryTime" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="createParams.remark" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="卡号前缀" prop="cardNoPrefix">
          <el-input v-model.trim="createParams.cardNoPrefix" size="mini"></el-input>
          <el-button size="mini" type="primary" @click="checkCardNoPrefix">检测前缀是否已存在</el-button>
        </el-form-item>
        <el-form-item label="卡号起始" prop="startCardNum">
          <el-input v-model="createParams.startCardNum" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="数量" prop="num">
          <el-input v-model="createParams.num" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="卡ID" prop="cardId">
          <el-select v-model="createParams.cardId" placeholder="请选择卡ID" size="mini">
            <el-option v-for="(item,index) in cardNameList" :key="index" :label="item.cardName"
                       :value="`${item.id}`"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="卡类型" prop="cardType">
          <el-select v-model="createParams.cardType" placeholder="请选择卡类型" size="mini">
            <el-option label="电子卡" value="1"></el-option>
            <el-option label="实体卡" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="createParams.cardType == 1" label="场景" prop="cardPic">
          <template slot-scope="">
            <div style="width: 40%;">
              <el-button size="mini" type="primary" @click="selectCardPic">获取场景图片</el-button>
            </div>

            <div class="demo-image__preview">
              <el-image
                  style="width: 100px; height: 100px"
                  :src="cardPic.cardShareDetailPicUrl"
                  :preview-src-list="getSrcList(cardPic.cardShareDetailPicUrl)">
              </el-image>
            </div>
          </template>
        </el-form-item>
        <el-form-item v-if="createParams.cardType==1" label="手机号" prop="phone">
          <div style="display: flex">
            <div style="width: 40%">
              <el-input v-model="createParams.phone" size="mini"></el-input>
            </div>
            <div style="width: 40%;margin-left: 10px">
              <el-button size="mini" type="primary" @click="getPhoneByUser">获取用户信息</el-button>
            </div>
          </div>
        </el-form-item>
        <template v-if="createParams.cardType == 1">
          <el-form-item label="用户ID" prop="userId">
            <el-input v-model="userId" :disabled="true" size="mini"></el-input>
          </el-form-item>
          <el-form-item label="用户名" prop="userName">
            <el-input v-model="userName" :disabled="true" size="mini"></el-input>
          </el-form-item>
        </template>

        <el-form-item>
          <el-button size="mini" type="primary" @click="xiTunBaoCardItemCreate">立即生成</el-button>
          <el-button size="mini" @click="createDialog=false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>


    <el-dialog :visible.sync="cardStatus" title="修改卡片状态" top="50px" width="30%">
      <div>
        <el-button size="mini" type="danger" @click="confirmEditCardStatus(-1)">全部未开通</el-button>
        <el-button size="mini" type="success" @click="confirmEditCardStatus(0)">全部已开通</el-button>
        <el-button size="mini" type="primary" @click="confirmEditCardStatus(1)">全部已发货</el-button>
        <el-button size="mini" type="warning" @click="confirmEditCardStatus(4)">全部已退款</el-button>
      </div>
      <label> 开通期限(开通需要填写数字，表示月份)：</label>
      <div>
        <el-tooltip class="item inline" effect="dark" content="开通需要填写数字，表示月份" placement="top">
          <el-input v-model="openDuration" size="mini" style="width: 20% !important; margin-bottom: 0; !important;"></el-input>
        </el-tooltip>
      </div>

    </el-dialog>

    <!-- 查询卡的所有图片   start -->
    <el-dialog title="卡图片列表" :visible.sync="selectCardPicDialog" :modal="false" width="60%">
      <label class="el-form-item__label fw-bolder text-dark" style="width: 90px; padding-right: 7px;">图片名称：</label>
      <div class="demo-input-suffix">
        <el-input
            placeholder="请输入内容"
            prefix-icon="el-icon-search"
            @change="getCardPicList"
            v-model.trim="picParams.cardPicName"
            clearable @clear="getCardPicList">
        </el-input>
      </div>
      <!-- 列表数据展示 -->
      <el-table class="m-auto" :data="cardPicList" style="width: 100%" height="70vh"
                element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)"
                v-loading="picLoading">
        <el-table-column prop="id" label="卡图片ID" fixed width="100" align="center">
        </el-table-column>

        <el-table-column
            label="卡图片名称"
            prop="cardPicName"
            width="120">
        </el-table-column>
        <el-table-column
            label="卡图片详情"
            prop="cardPicName"
            width="120">
          <template slot-scope="scope">
            <div class="demo-image__preview">
              <el-image
                  style="width: 100px; height: 100px"
                  :src="scope.row.cardPicUrl"
                  :preview-src-list="getSrcList(scope.row.cardPicUrl)">
              </el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            label="卡分享详情图名称"
            prop="cardShareDetailPicName"
            width="140">
        </el-table-column>
        <el-table-column
            label="卡分享详情图"
            prop="cardPicName"
            width="120">
          <template slot-scope="scope">
            <div class="demo-image__preview">
              <el-image
                  style="width: 100px; height: 100px"
                  :src="scope.row.cardShareDetailPicUrl"
                  :preview-src-list="getSrcList(scope.row.cardShareDetailPicUrl)">
              </el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            label="卡分享省略图"
            prop="cardPicName"
            width="120">
          <template slot-scope="scope">
            <div class="demo-image__preview">
              <el-image
                  style="width: 100px; height: 100px"
                  :src="scope.row.cardSharePreviewPicUrl"
                  :preview-src-list="getSrcList(scope.row.cardSharePreviewPicUrl)">
              </el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            label="卡分享详情图背景色"
            prop="bgColor"
            width="150">
        </el-table-column>
        <el-table-column
            label="卡分享LOGO图"
            width="120">
          <template slot-scope="scope">
            <div class="demo-image__preview">
              <el-image
                  style="width: 100px; height: 100px"
                  :src="scope.row.logoUrl"
                  :preview-src-list="getSrcList(scope.row.logoUrl)">
              </el-image>
            </div>
          </template>
        </el-table-column>

        <el-table-column fixed="right" label="操作" width="100" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="addCardPicInCardUser(scope.row)">
              选择该卡
            </el-button>
          </template>
        </el-table-column>

      </el-table>
      <el-pagination
          ref="pagination"
          style="margin-top: 10px;text-align: center"
          @size-change="picHandleSizeChange"
          @current-change="picHandleCurrentChange"
          :current-page.sync="picParams.current"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="picParams.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="picTotalcount">
      </el-pagination>
    </el-dialog>
    <!-- 查询卡的所有图片   end-->

    <!-- 查询卡绑定的手机号   start -->
    <el-dialog title="卡绑定手机号列表" :visible.sync="mobileReservationDialog" :modal="false" width="60%">
      <label class="el-form-item__label fw-bolder text-dark" style="width: 90px; padding-right: 7px;">手机号：</label>
      <div class="demo-input-suffix">
        <el-input
            style="width: 30%"
            placeholder="请输入内容"
            prefix-icon="el-icon-search"
            @change="getCardUserReservationList"
            v-model.trim="mobileParams.mobile"
            clearable @clear="getCardUserReservationList">
        </el-input>
        <label style="margin-left: 10px"> 不对的手机号：<span> {{ errorMobile }}</span> </label>

        <div class="ms-10">
          <input ref="file" name="file" style="margin-top: 20px;margin-bottom: 10px;" type="file">
          <el-tooltip class="item" effect="dark" content="上传文件数据手机号与卡用户id绑定" placement="top">
            <el-button size="mini" type="primary" @click="uploadMobileFile">提交文件</el-button>
          </el-tooltip>
        </div>
      </div>

      <!-- 列表数据展示 -->
      <el-table class="m-auto" :data="mobileReservationList" style="width: 100%" height="70vh"
                element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)"
                v-loading="mobileLoading">
        <el-table-column prop="id" label="预绑ID" fixed width="100" align="center">
        </el-table-column>
        <el-table-column
            label="卡用户ID"
            prop="cardUserId"
            width="120">
        </el-table-column>
        <el-table-column
            label="用户Id"
            prop="userId"
            width="140">
        </el-table-column>
        <el-table-column
            label="手机号"
            prop="mobile"
            width="150">
        </el-table-column>
        <el-table-column
            width="120"
            label="状态">
          <template slot-scope="{row}">
            {{ row.status | mobileStatusF }}
          </template>
        </el-table-column>
        <el-table-column
            prop="createTime"
            label="创建时间">
        </el-table-column>
        <el-table-column
            prop="receiveTime"
            label="领取时间">
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100" align="center">
          <template slot-scope="scope">

          </template>
        </el-table-column>

      </el-table>
      <el-pagination
          ref="pagination"
          style="margin-top: 10px;text-align: center"
          @size-change="mobileHandleSizeChange"
          @current-change="mobileHandleCurrentChange"
          :current-page.sync="mobileParams.current"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="mobileParams.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="mobileTotalcount">
      </el-pagination>
    </el-dialog>
    <!-- 查询卡绑定的手机号  end-->

  </div>
</template>
<script>

import sd from "silly-datetime";
import {
  deleteXiTunBaoCardItemCreateApi,
  editRemarkApi,
  exportXiTunBaoCardListApi,
  getPhoneByUserApi,
  getXiTunBaoCardListApi,
  getXiTunBaoCardNameListApi,
  xiTunBaoCardItemCreateApi,
  editCardStatusApi,
  checkCardNoPrefixApi,
  getCardUserReservationListApi,
  uploadMobileFileApi
} from "@/api/backend/xitunbaoApi";
import KeyList from "@/views/xitunbao/XiTunBaoCard/components/keyList.vue";
import {getCardPicListApi} from "@/api/backend/xitunbaoCardApi";

export default {
  name: "XiTunBaoOrder",
  components: {KeyList},
  data() {
    var checkUserId = (rule, value, callback) => {
      if (this.createParams.cardType == 1) {
        if (!this.userId) {
          return callback(new Error('用户ID不能为空'));
        }
      }
      callback();
    };
    return {
      cardData: {},
      cardStatus: false,
      rows: [],
      size: 10,
      current: 1,
      total: 0,
      loading: false,
      loadingMsg: "列表加载中...",
      rules: {
        remark: [
          {required: true, message: '请输入卡备注', trigger: 'blur'},
        ],
        cardNoPrefix: [
          {required: true, message: '请输入卡号前缀', trigger: 'blur'}
        ],
        startCardNum: [
          {required: true, message: '请请输入卡号起始', trigger: 'blur'}
        ],
        num: [
          {required: true, message: '请输入生成数量', trigger: 'blur'}
        ],
        cardId: [
          {required: true, message: '请选择卡ID', trigger: 'blur'}
        ],
        cardType: [
          {required: true, message: '请选择卡类型', trigger: 'blur'}
        ],
        /*userId: [
          {validator: checkUserId, trigger: 'blur'}
        ],*/
        isOpen: [
          {required: true, message: '请选择是否开通', trigger: 'blur'}
        ],
      },
      params: {
        current: 1,
        size: 10,
        remark: "",
        cardId: "",
        cardType: "",
        useUserId: ""
      },
      cardNameList: [],
      value2: [],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      dialogTitle: "查看卡密",
      dialogVisible: false,
      item: {},
      createDialog: false,
      createParams: {
        num: "",
        cardId: "",
        cardType: "",
        remark: "",
        startCardNum: "",
        cardNoPrefix: "",
        userId: "",
        cardPicId: "",
        isOpen: "",
        openDuration: 0,
        expiryTime: ""
      },
      phone: "",
      userId: "",
      userName: "",

      selectCardPicDialog: false,
      picLoading: false,
      picTotalcount: 99,
      cardPicList: [],
      picParams: {
        current: 1,
        size: 10,
        cardPicName: ""
      },
      cardPic: {},
      openDuration: "",

      mobileLoading: false,
      mobileReservationList: [],
      mobileParams: {
        current: 1,
        size: 10,
        mobile: "",
        parentId: "",
        cardUserId: ""
      },
      mobileTotalcount: 99,
      mobileReservationDialog: false,
      errorMobile: ""
    }
  },
  filters: {

    formatDate(value) {
      return sd.format(+value)
    },
    cardTypeF(value) {
      switch (value) {
        case "1":
          return '电子卡'
        case "2":
          return '实体卡'
      }
    },
    mobileStatusF(value) {
      // 状态:0=未使用,1=已发货,2=已使用,3=申请退款,4=已退款
      switch (value) {
        case "-2":
          return '领取时出错'
        case "-1":
          return '禁用'
        case "0":
          return '未使用'
        case "1":
          return '已使用'
      }
    },
  },
  watch: {
    'createParams.openDuration'(newVal) {
      this.calculateExpirationDate(newVal);
    },
  },
  methods: {
    async getPhoneByUser() {
      let {data} = await getPhoneByUserApi({
        phone: this.createParams.phone
      })
      this.$message({
        message: '获取成功',
        type: 'success'
      });
      this.userId = data.id;
      this.userName = data.nickname;
    },
    deleteCard(item) {

      this.$confirm('此操作将永久删除该批次及卡密，是否确认继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await deleteXiTunBaoCardItemCreateApi(item)
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
        this.getCardList();
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    initCreateDialog() {
      this.createParams = {
        num: "",
        cardId: "",
        cardType: "",
        remark: "",
        startCardNum: "",
        cardNoPrefix: ""
      }
      this.phone = ""
      this.userId = ""
      this.userName = ""
    },
    async xiTunBaoCardItemCreate() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (this.createParams.cardType == 1 && !this.createParams.cardPicId) {
            this.$message({
              message: '请选择场景图片',
              type: 'warning'
            });
            return;
          }
          this.createParams.userId = this.userId;
          await xiTunBaoCardItemCreateApi(this.createParams)
          this.createDialog = false
          this.getCardList();
          this.createParams = {
            num: "",
            cardId: "",
            cardType: "",
            remark: "",
            startCardNum: "",
            cardNoPrefix: "",
            userId: "",
            cardPicId: "",
            isOpen: "",
            openDuration: 0,
            expiryTime: ""
          };
          this.cardPic = {};
        } else {
          this.cardPic = {};
          return false;
        }
      });

    },
    async exportCardList() {
      this.loadingMsg = "文件导出中..."
      this.loading = true;
      const response = await exportXiTunBaoCardListApi(this.params)
      const blob = new Blob([response], {type: 'application/octet-stream'});
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${sd.format(new Date())}.xlsx`; // 设置下载的文件名，根据需要修改
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      this.$message({
        message: '文件下载成功',
        type: 'success'
      });
      this.loading = false;
    },
    editRemark(item) {
      this.$prompt('请输入修改的备注', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: item.remark
      }).then(async ({value}) => {
        await editRemarkApi({
          id: item.id,
          remark: value
        })
        this.$message({
          type: 'success',
          message: '操作成功'
        });
        await this.getCardList();
      }).catch(() => {

      });
    },
    getXiTunBaoCardDetail(item) {
      this.dialogVisible = true;
      this.dialogTitle = item.remark;
      this.item = item;
      //this.$refs.KeyList.initList(item);
    },
    resetListParams() {
      this.value2 = []
      this.params = {
        current: 1,
        size: 10,
        remark: "",
        cardType: "",
        startCreateTime: "",
        endCreateTime: ""
      }
      this.getCardList();
    },
    async getCardList() {
      this.params.startCreateTime = ""
      this.params.endCreateTime = ""
      this.loading = true;
      this.loadingMsg = "列表加载中...";
      this.params.startCreateTime = this.value2.length > 0 ? `${+this.value2[0]}`.slice(0, 10) : ""
      this.params.endCreateTime = this.value2.length > 0 ? `${+this.value2[1]}`.slice(0, 10) : ""
      let {data} = await getXiTunBaoCardListApi(this.params);
      this.rows = data.rows
      this.size = data.size
      this.current = data.current
      this.total = data.total
      this.loading = false;
    },
    /**
     * 分页大小切换时触发
     * @param val
     */
    handleSizeChange(val) {
      this.params.size = val;
      this.getCardList();
    },
    /**
     * 页码切换时触发
     * @param val
     */
    handleCurrentChange(val) {
      this.params.current = val;
      this.getCardList();
    },
    async initCardNameList() {
      let {data} = await getXiTunBaoCardNameListApi();
      this.cardNameList = data
    },
    // 点击按钮时触发的方法，用于确认操作
    confirmEditCardStatus(status) {
      // 显示确认弹窗
      this.cardStatus = true;
      if (status === 0 && !this.openDuration) {
        this.$message({
          message: '请输入开通时长',
          type: 'error'
        });
        return;
      }
      // 通过 MessageBox.confirm 方法显示二次确认对话框
      this.$confirm('确定要执行此操作吗？', '提示', {
        confirmButtonText: '确定', // 确定按钮的文本
        cancelButtonText: '取消', // 取消按钮的文本
        type: 'warning' // 消息框的类型，警告类型
      }).then(() => {
        // 用户点击了确定按钮，执行操作
        this.editCardStatus(this.cardData, status);
      }).catch(() => {
        // 用户点击了取消按钮，关闭弹窗
        this.cardStatus = false;
      });
      this.cardStatus = false;
    },
    /**
     * 修改卡的状态
     */
    async editCardStatus(item, status) {
      //  item.mdlOrder 不为空 有值代表cardUser的parentId
      let card = {
        ...item,
        parentId: item.mdlOrder,
        status: status,
        openDuration: this.openDuration
      }
      await editCardStatusApi(card);
      this.openDuration = "";
      this.getCardList();
      this.initCardNameList();
    },
    /* 卡密生成 */
    /**
     * 查询卡图片集合
     */
    selectCardPic() {
      this.selectCardPicDialog = true;
      this.getCardPicList();
    },
    /**
     * 分页大小切换时触发
     * @param val
     */
    picHandleSizeChange(val) {
      this.picParams.size = val;
      this.getCardPicList();
    },
    /**
     * 页码切换时触发
     * @param val
     */
    picHandleCurrentChange(val) {
      this.picParams.current = val;
      this.getCardPicList();
    },
    /**
     * 获取所有场景图片
     */
    async getCardPicList() {
      this.picLoading = true;
      let {data} = await getCardPicListApi(this.picParams)
      this.picLoading = false;
      this.cardPicList = data.rows;
      this.picTotalcount = data.total;
    },
    /* 卡密生成 的场景图片获取*/
    getSrcList(cardPicUrl) {
      if (cardPicUrl) {
        return cardPicUrl.split(',');
      }
    },
    /**
     * 将图片添加到卡中
     */
    async addCardPicInCardUser(item) {
      this.$confirm('此操作将会一键将该图片绑定到卡密场景?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info'
      }).then(async () => {
        this.cardPic = item;
        this.createParams.cardPicId = item.id;
        this.selectCardPicDialog = false;
        this.$message({
          type: 'success',
          message: '选择成功!'
        });
      })
    },
    /**
     * 计算到期时间
     */
    calculateExpirationDate(months) {
      if (months) {
        const monthsToAdd = parseInt(this.createParams.openDuration, 10);
        const currentDate = new Date();
        const newDate = new Date(currentDate.setMonth(currentDate.getMonth() + monthsToAdd));
        this.createParams.expiryTime = sd.format(newDate, 'YYYY-MM-DD HH:mm:ss');
      } else {
        this.createParams.expiryTime = '';
      }
    },
    /**
     * 检查前缀
     */
    async checkCardNoPrefix() {
      if (!this.createParams.cardNoPrefix || this.createParams.cardNoPrefix.trim() === '') {
        this.$message({
          message: '请填写卡号前缀后再检测！！！',
          type: 'warning',
        });
        return;
      }
      let {data} = await checkCardNoPrefixApi({cardNoPrefix: this.createParams.cardNoPrefix})
      if (!data) {
        this.$message({
          message: '卡号前缀没有重复',
          type: 'success',
        });
      } else {
        this.$message({
          message: '卡号前缀可能重复或者容易混淆。例如已存在的卡号前缀：' + data[0],
          type: 'error',
        });
      }
    },
    /**
     * 分页大小切换时触发
     * @param val
     */
    mobileHandleSizeChange(val) {
      this.mobileParams.size = val;
      this.getCardUserReservationList();
    },
    /**
     * 页码切换时触发
     * @param val
     */
    mobileHandleCurrentChange(val) {
      this.mobileParams.current = val;
      this.getCardUserReservationList();
    },
    /**
     * 获取手机号和卡绑定的列表
     */
    async getCardUserReservationList() {
      let {data} = await getCardUserReservationListApi(this.mobileParams);
      this.mobileTotalcount = data.count;
      this.mobileReservationList = data.rows;
    },
    /**
     * 打开用户卡和手机号绑定
     */
    getCardUSerBindMobile(mdlOrder, cardUserId) {
      if (cardUserId !== null && cardUserId !== undefined && cardUserId !== '' && cardUserId !== "") {
        this.mobileParams.cardUserId = cardUserId;
        this.mobileParams.parentId = "";
      }
      if (mdlOrder !== null && mdlOrder !== undefined && mdlOrder !== '' && mdlOrder !== "") {
        this.mobileParams.parentId = mdlOrder;
        this.mobileParams.cardUserId = "";
      }
      this.mobileReservationDialog = true;
      this.getCardUserReservationList();
    },
    /**
     * 上传文件
     */
    async uploadMobileFile() {
      let file = this.$refs.file.files[0];
      if (!file) {
        this.$message({
          message: '请先选择文件再上传！谢谢！',
          type: 'warning'
        });
        return;
      }
      let formData = new FormData();
      formData.append('file', file);
      formData.append('parentId', this.mobileParams.parentId);
      formData.append('cardUserId', this.mobileParams.cardUserId);
      let {data} = await uploadMobileFileApi(formData);
      if ('success' === data) {
        this.$message({
          message: '导入成功',
          type: 'success'
        });
        this.errorMobile = null;
      } else {
        this.errorMobile = data.join(', ');
        this.$message({
          message: '部分手机号格式不对！' + this.errorMobile + '不符合手机号格式',
          type: 'error'
        });
      }
      this.getCardUserReservationList();
    },
  },
  created() {
    this.getCardList();
    this.initCardNameList();
  }
}
</script>
<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>