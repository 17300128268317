<template>
  <el-dialog
      ref="addressDialog"
      :visible.sync="dialogVisible"
      append-to-body
      title="选择地址"
      width="40%"
      @open="initAddressData"
  >
    <!-- 已选地址展示 -->
    <div class="address-container">
      <div class="selected-addresses">
        <el-tag
            v-for="(address, key) in internalAddresses"
            :key="key"
            closable
            @close="removeAddress(key)"
            class="address-tag"
        >
          {{ key }}
        </el-tag>
      </div>
      <div class="operation-buttons">
        <el-button
            type="danger"
            size="small"
            @click="clearAllAddresses"
            :disabled="Object.keys(internalAddresses).length === 0"
        >
          一键清空
        </el-button>
      </div>
    </div>

    <!-- 选择表单 -->
    <el-form ref="addressForm" :model="addressForm" label-width="80px">
      <!-- 省选择 -->
      <el-form-item label="省份" required>
        <el-select
            v-model="addressForm.provinceId"
            placeholder="请选择省份"
            @change="handleProvinceChange"
        >
          <el-option
              v-for="item in provinceList"
              :key="item.kid"
              :label="item.name"
              :value="item.kid"
          />
        </el-select>
      </el-form-item>

      <!-- 市选择 -->
      <el-form-item label="城市" required>
        <el-select
            v-model="addressForm.cityId"
            placeholder="请选择城市"
            @change="handleCityChange"
        >
          <el-option
              v-for="item in cityList"
              :key="item.kid"
              :label="item.name"
              :value="item.kid"
          />
        </el-select>
      </el-form-item>

      <!-- 区选择 -->
      <el-form-item label="区县" required>
        <el-select
            v-model="addressForm.countyId"
            placeholder="请选择区县"
        >
          <el-option
              v-for="item in countyList"
              :key="item.kid"
              :label="item.name"
              :value="item.kid"
          />
        </el-select>
      </el-form-item>
    </el-form>

    <span slot="footer">
      <el-button @click="resetForm">重置</el-button>
      <el-button
          type="primary"
          @click="saveAddress"
          :disabled="!addressForm.countyId"
      >
        添加地址
      </el-button>
      <el-button @click="handleSubmit">完成选择</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {findBackendJdRegionApi} from "@/api/backend/addressApi";

export default {
  props: {
    selectedAddresses: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      dialogVisible: false,
      internalAddresses: {},
      addressForm: {
        provinceId: '',
        cityId: '',
        countyId: ''
      },
      provinceList: [],
      cityList: [],
      countyList: []
    };
  },
  watch: {
    selectedAddresses: {
      immediate: true,
      deep: true,
      handler(newVal) {
        this.internalAddresses = { ...newVal };
      }
    }
  },
  methods: {
    openDialog() {
      this.dialogVisible = true;
    },
    // 初始化弹窗数据
    async initAddressData() {
      this.resetForm();
      await this.loadRegionData('provinceList', 0);
    },

    // 区域数据加载
    async loadRegionData(type, parentId) {
      try {
        const { data } = await findBackendJdRegionApi({ id: parentId });
        this[type] = data;
      } catch (error) {
        this.$message.error('区域数据加载失败');
      }
    },

    // 省选择变化
    handleProvinceChange(provinceId) {
      this.addressForm.cityId = '';
      this.addressForm.countyId = '';
      this.cityList = [];
      this.countyList = [];
      this.loadRegionData('cityList', provinceId);
    },

    // 市选择变化
    handleCityChange(cityId) {
      this.addressForm.countyId = '';
      this.countyList = [];
      this.loadRegionData('countyList', cityId);
    },

    // 保存单个地址
    saveAddress() {
      if (!this.addressForm.countyId) {
        this.$message.warning('请完整选择省市区');
        return;
      }

      const province = this.provinceList.find(p => p.kid === this.addressForm.provinceId);
      const city = this.cityList.find(c => c.kid === this.addressForm.cityId);
      const county = this.countyList.find(c => c.kid === this.addressForm.countyId);

      if (!province || !city || !county) {
        this.$message.error('地址信息异常');
        return;
      }

      const addressKey = `${province.name}/${city.name}/${county.name}`;

      // 防止重复添加
      if (this.internalAddresses[addressKey]) {
        this.$message.warning('该地址已存在');
        return;
      }

      this.$set(this.internalAddresses, addressKey, {
        pid: this.addressForm.provinceId,
        cid: this.addressForm.cityId,
        did: this.addressForm.countyId
      });

      this.resetForm();
      this.$message.success('地址添加成功');
    },

    // 删除单个地址
    removeAddress(key) {
      this.$delete(this.internalAddresses, key);
    },

    // 清空所有地址
    clearAllAddresses() {
      this.$confirm('确定要清空所有已选地址吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.internalAddresses = {};
      });
    },

    // 最终提交
    handleSubmit() {
      this.$emit('update:addresses', this.internalAddresses);
      this.dialogVisible = false;
    },

    // 重置表单
    resetForm() {
      this.addressForm = {
        provinceId: '',
        cityId: '',
        countyId: ''
      };
      this.cityList = [];
      this.countyList = [];
    }
  }
};
</script>

<style scoped>
.address-container {
  margin-bottom: 20px;
  padding: 15px;
  background: #f5f7fa;
  border-radius: 4px;
}

.selected-addresses {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
}

.operation-buttons {
  text-align: right;
  margin-bottom: 15px;
}

.address-tag {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>