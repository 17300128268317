import {commentType} from "@/utils/commentType";
import {getCategoryListByTopicApi,getCategory1ListByTopicApi } from "@/api/admin/categoryApi";
import {clearTopicApi, deleteGoodsApi, getSelectedPageApi, updateSortApi, getSelectedTotalApi, getGoodsPriceTrendApi, updateTopicGoodsDisPriceApi} from "@/api/admin/topicApi";
import { editDisPriceApi } from "@/api/backend/goodsManageApi.js";
import {exportExcelApi} from "@/api/backend/export"
import ObjectUtil from "@/utils/ObjectUtil";
import SelGoods from "@/views/SelectGoods/components/SelGoods/index.vue";
import sd from "silly-datetime";
import {findBackendJdRegionApi, getGoodsDetailByIdsApi} from "@/api/backend/addressApi";
import JdAddressListDelivery from "@/components/JdAddressListDelivery/index.vue";

/**
 * 选品商品
 */
export const mixins = {
    name: "SelGoods",
    data() {
        return {
            parentDialogVisible: false,
            selectedAddresses: JSON.parse(localStorage.getItem('selectedAddresses')) || {},
            dialog: false,
            item: {},
            index: 1,
            addressDialog: false,
            selectionGoods: {
                status: 1,
                supplyRate: "",
                scribingType: "",
                scribingRatio: 0.8,
                topicId: "",
                commentType: "",
                goodsId: "",
                current: 1,
                size: 10,
                total: 999,
                isJd: 1,
                title: "",
                categoryId1Name: "",
                categoryId2Name: "",
                level: "",
                phone: "",
                minSupplyPrice: "",
                maxSupplyPrice: "",
                minProfitMargin: "",
                maxProfitMargin: "",
                priceSort: "",
                sortOrder: [],
                addressMapList: {},
                fromTopic: ""
            },
            categoryId1: [],
            categoryId2: [],
            selectGoods: [],
            total: 999,
            loading: false,
            excelLoading: false,
            idArr: [],
            addressObj: {
                provinceId: "",
                cityId: "",
                countyId: "",
                townId: ""
            },
            addressList: {
                provinceList: [],
                cityList: [],
                countyList: [],
                townList: []
            },
            skuListFiler: [],
            selGoodsList: [],
            rate: 1.25,
            jdRate: 1,
            systemRate: 0.800,
            chartDialogVisible: false,
            chartData: {}
        };
    },
    components: {SelGoods,JdAddressListDelivery},
    props: {
        items: {}
    },
    computed: {},
    filters: {},
    methods: {
        openSearchTab(skuName) {
            // 打开新的搜索标签页
            window.open(
                `https://search.jd.com/Search?keyword=${encodeURIComponent(skuName)}`,
                '_blank'
            );
        },
        showAddressDialog() {
            // 使用nextTick确保父弹窗完全打开
            this.$nextTick(() => {
                this.$refs.jdAddressListDelivery.openDialog();
            });
        },
        handleAddressUpdate(newAddresses) {
            this.selectedAddresses = newAddresses;
            this.selectionGoods.addressMapList = this.selectedAddresses;
            this.getList(); // 直接触发列表刷新
        },
        async viewPriceTrend(item) {
            let {data} = await getGoodsPriceTrendApi({skuId: item.skuId});
            this.chartData = {
                ...data,
                skuName: item.title
            };
            this.chartDialogVisible = true;
        },
        chartDialogVisibleFn(val){
            this.chartDialogVisible = val;
        },
        /**
         * 修改表格颜色
         */
        handleCommand(command) {
            const row = this.scope?.row; // 确保能拿到行的数据
            switch (command) {
                case "setSort":
                    this.setSort(row); // 执行修改顺序操作
                    break;
                case "viewPriceTrend":
                    // 执行查看价格趋势的操作
                    break;
                case "delGoods1":
                    this.delGoods(row, 1); // 执行删除当前库该商品的操作
                    break;
                case "delGoods2":
                    this.delGoods(row, 2); // 执行删除所有库该商品的操作
                    break;
            }
        },
        //  放大图片
        getSrcList(cardPicUrl) {
            if (cardPicUrl) {
                return cardPicUrl.split(',');
            }
        },
        checkClickUrl(item) {
           if (!item) {
               return;
           }
            const newWindow = window.open(item.jdUrl, '_blank');

            // 设定一个定时器，检查新页面的 URL 是否被重定向
            const checkRedirect = setInterval(() => {
                try {
                    let currentUrl = newWindow.location.href;
                    // 如果新窗口已经加载并且 URL 改变
                    if (currentUrl.includes('https://trade.m.jd.com/common/limit.html?module=detail_m1')) {
                        console.log('重定向到指定页面')
                        // 如果重定向到指定页面，执行特定操作
                        clearInterval(checkRedirect);
                        this.editDisPrice(item);
                    }
                    console.log('当前页面的 URL:', newWindow.location.href)
                } catch (e) {
                    // 浏览器的跨域限制可能会阻止访问 location.href
                    // 在跨域时，允许进行轮询，直到可以访问新窗口的 location 或者关闭
                    // 代表被重定向了 直接调用相关方法
                    clearInterval(checkRedirect);
                    this.editDisPrice(item);
                }

                // 如果新窗口关闭，停止轮询
                if (newWindow.closed) {
                    clearInterval(checkRedirect);
                }
            }, 1000); // 每秒检测一次
        },
        async editDisPrice(item) {
            let skuIdList = item;
            if (this.selectGoods) {
                skuIdList = this.selectGoods.map(item => item.skuId);
            }
            let requestData = {
                "skuIdList": skuIdList
            };
            // await editDisPriceApi({skuId: item.skuId});
            await editDisPriceApi(requestData);
            this.getList();
        },
        //  修改价格
        editRate() {
            if (this.rate) {
                this.rate = Number(this.rate);
                // this.getList();
                this.systemRate = (Number(this.selectGoods[0].supplPrice) / (Number(this.selectGoods[0].supplPrice) * this.rate)).toFixed(3)
                return;
            }
            if (this.jdRate) {
                this.jdRate = Number(this.jdRate);
                // this.getList();
                // this.systemRate = (this.selectGoods[0].disPrice / (this.selectGoods[0].disPrice * this.rate)).toFixed(3)
            }
        },
        // 打开商品的京东链接
        openLink() {
            this.linkLoading = false;

            // 获取 selGoodsList 中的 skuId 列表
            var skuIds = this.selectGoods.map(item => item.skuId);
            // 定义基础搜索链接
            // var searchUrl = 'https://so.m.jd.com/ware/search.action?keyword=%E6%89%8B%E6%9C%BA&searchFrom=search&sf=11&as=1&sourceType=H5_home_page_search';
            // 定义基础商品详情链接
            // var baseUrl = 'https://item.m.jd.com/product';
            var baseUrl = 'https://item.jd.com/';
            // 创建商品链接列表
            var links = skuIds.map(skuId => baseUrl + skuId + '.html?extension_id=eyJhZCI6IjEyMjMiLCJjaCI6IjIiLCJza3UiOiIxMDAwMzM3MjQyODAiLCJ0cyI6IjE3MjU3Njk5NTUiLCJ1bmlxaWQiOiJ7XCJjbGlja19pZFwiOlwiMTg4NmEzMDktZTU2ZC00YmQ3LWIyY2QtZGI1NjFkMmFiYzgyXCIsXCJtYXRlcmlhbF9pZFwiOlwiOTAwNjQ4NDQwNTkxNzI2MDE3NVwiLFwicG9zX2lkXCI6XCIxMjIzXCIsXCJzaWRcIjpcImI5NmRjZWY3LTBlYTctNGU5OC04NTRjLTBkYjc0NDMxMDRjZVwifSJ9&jd_pop=1886a309-e56d-4bd7-b2cd-db561d2abc82&abt=0');

            var count = 0; // 计数已经打开的链接次数
            var maxCount = 50; // 最大打开链接次数

            let that = this;

            function openLinks(links) {
                count++;
                if (links.length === 0) {
                    return;
                }
                // 打开搜索页面
                // window.location.href = searchUrl;
                // window.open(searchUrl, '_blank');
                // 在当前页面打开商品详情页面
                let newWindow = window.open(links.shift(), '_blank');

                if (!newWindow) {
                    console.log("窗口弹出被浏览器阻止或无法打开");
                    return;
                }

                // 设定一个定时器，检查新页面的 URL 是否被重定向
                const checkRedirect = setInterval(() => {
                    try {
                        let currentUrl = newWindow.location.href;
                        // 如果新窗口已经加载并且 URL 改变
                        if (currentUrl.includes('https://trade.m.jd.com/common/limit.html?module=detail_m1')) {
                            console.log('重定向到指定页面')
                            // 如果重定向到指定页面，执行特定操作
                            clearInterval(checkRedirect);
                            let config = {
                                skuId: skuIds.shift()
                            }
                            that.editDisPrice(config);
                        }
                        console.log('当前页面的 URL:', currentUrl)
                    } catch (e) {
                        // 浏览器的跨域限制可能会阻止访问 location.href
                        // 在跨域时，允许进行轮询，直到可以访问新窗口的 location 或者关闭
                        // 代表被重定向了 直接调用相关方法
                        clearInterval(checkRedirect);
                        let config = {
                            skuId: skuIds.shift()
                        }
                        that.editDisPrice(config);
                    }

                    // 如果新窗口关闭，停止轮询
                    if (newWindow.closed) {
                        clearInterval(checkRedirect);
                    }
                }, 1000); // 每秒检测一次

                // 设置延迟确保页面加载完成
                setTimeout(function () {
                    var link = links.shift(); // 从数组中取出第一个商品链接
                    console.log("即将打开商品链接:", link);

                    // 休眠次数
                    if (count == maxCount) {
                        console.log("到达休眠范围，开始休眠");
                        count = 0;
                        var randomNum = Math.floor(Math.random() * 1200000) + 420000; // 休眠10到20分钟
                        setTimeout(function () {
                            console.log('休眠结束，继续打开链接');
                            openLinks(links); // 继续打开
                        }, randomNum);
                    } else {
                        var randomDelay = Math.floor(Math.random() * 10000) + 2000; // 随机延迟
                        setTimeout(function () {
                            openLinks(links); // 递归调用，打开下一个链接
                        }, randomDelay);
                    }
                }, 2000); // 延迟5秒，确保搜索页面加载完成
            }
            openLinks(links); // 开始打开链接
            window.focus();
            this.linkLoading = false;
        },
        /*openLink() {
            this.linkLoading = false;

            // 获取 selGoodsList 中的 skuId 列表
            var skuIds = this.selectGoods.map(item => item.skuId);

            // 定义基础搜索链接
            var searchUrl = 'https://so.m.jd.com/ware/search.action?keyword=%E6%89%8B%E6%9C%BA&searchFrom=search&sf=11&as=1&sourceType=H5_home_page_search';

            // 在新窗口打开搜索页面，随机选择一个商品链接，并替换skuId
            var count = 0; // 计数已经打开的链接次数
            var maxCount = 50; // 最大打开链接次数

            function openLinks(skuIds) {
                if (skuIds.length === 0) {
                    return;
                }
                count++;
                var skuId = skuIds.shift(); // 从数组中取出第一个 skuId

                // 打开搜索页面
                 window.open(searchUrl, '_blank');

                // 设置延迟来确保页面加载完毕
                setTimeout(function () {
                    try {
                        // 检查是否存在遮罩层并自动点击“直接访问”按钮
                        // eslint-disable-next-line no-inner-declarations
                        /!*function checkAndClickOverlay() {
                            // 判断是否存在遮罩层
                            const overlay = searchWindow.document.querySelector("#id-pcprompt-mask");
                            if (overlay) {
                                console.log("发现遮罩层，尝试点击 '直接访问' 按钮...");
                                // 找到并点击右上角的直接访问按钮
                                const accessButton = searchWindow.document.querySelector(".halo-pcprompt-title");
                                if (accessButton) {
                                    accessButton.click();
                                    console.log("已点击 '直接访问' 按钮");
                                } else {
                                    console.log("未找到 '直接访问' 按钮");
                                }
                            } else {
                                console.log("没有遮罩层");
                            }
                        }*!/

                        // 在页面加载完成后检查并点击遮罩层
                        setTimeout(3000); // 3秒后检查遮罩层

                        // 模拟点击商品详情页（可以通过检测DOM元素来选择任意一个商品）
                        /!*var productLink = searchWindow.document.querySelector('a.some-product-selector'); // 替换成你所选的商品选择器
                        if (productLink) {
                            var productUrl = productLink.href;

                            // 替换链接中的 product SKU
                            var newLink = productUrl.replace(/product\/\d+/, 'product/' + skuId);

                            // 打开新链接
                            var newWindow = window.open(newLink, '_blank');
                            console.log('打开了商品链接：', newLink);

                            // 随机关闭窗口
                            var randomClose = Math.floor(Math.random() * 10000) + 1000; // 生成 1000 到 5000 毫秒之间的随机时间
                            setTimeout(function () {
                                newWindow.close();
                            }, randomClose);
                        }*!/
                    } catch (err) {
                        console.error('无法点击商品链接:', err);
                    }

                    // 如果达到最大计数，休眠随机时间后继续执行
                    if (count == maxCount) {
                        count = 0;
                        var randomNum = Math.floor(Math.random() * 1200000) + 420000; // 休眠10到20分钟
                        setTimeout(function () {
                            console.log('休眠结束，继续打开链接');
                            openLinks(skuIds); // 休眠结束后继续打开
                        }, randomNum);
                    } else {
                        // 随机延迟时间再打开下一个链接
                        var randomDelay = Math.floor(Math.random() * 30000) + 10000;
                        setTimeout(function () {
                            openLinks(skuIds);
                        }, randomDelay);
                    }
                }, 5000); // 等待5秒加载页面
            }

            openLinks(skuIds); // 调用函数开始打开链接
            window.focus();
            this.linkLoading = false;
        },*/
        tableRowClassName({row}) {

            if (row.deliveryStatus) {
                const hasError = Object.values(row.deliveryStatus).some(
                    status => !status.canPurchase
                );
                return hasError ? 'warning-row' : '';
            }

            if ((this.item.staffId === 2 && row.fromTopicId === 3) || (this.item.staffId === 3 && row.fromTopicId === 4)) {
                return 'exit-row';
            }

            if (row.status == 2) {
                return 'success-row';
            }

            if (((Number(row.supplPrice) * Number(this.rate) ) > Number(row.price ) * Number(this.jdRate)) || (Number(row.supplPrice) >= Number(row.price) * Number(this.jdRate)) ) {
                return 'success-row';
            }
            
            if ( ((Number(row.supplPrice) * Number(this.rate) ) > Number(row.price) ) || (Number(row.supplPrice) >= Number(row.price) ) ) {
                return 'warning-row';
            }

        },
        provinceChange(id) {
            this.findBackendJdRegion("cityList", id);
            this.addressObj.cityId = ""
            this.addressObj.countyId = ""
            this.addressObj.townId = ""
            this.addressList.cityList = []
            this.addressList.countyList = []
            this.addressList.townList = []
        },
        cityChange(id) {
            this.findBackendJdRegion("countyList", id);
            this.addressObj.countyId = ""
            this.addressObj.townId = ""
            this.addressList.countyList = []
            this.addressList.townList = []
        },
        countyChange(id) {
            this.findBackendJdRegion("townList", id);
            this.addressObj.townId = ""
            this.addressList.townList = []
        },
        addressDialogOpen() {
            this.addressObj.provinceId = localStorage.getItem("provinceId") || ""
            this.addressObj.cityId = localStorage.getItem("cityId") || ""
            this.addressObj.countyId = localStorage.getItem("countyId") || ""
            this.addressObj.townId = localStorage.getItem("townId") || ""

            this.findBackendJdRegion("provinceList", 0);
            if (this.addressObj.provinceId) {
                this.findBackendJdRegion("cityList", this.addressObj.provinceId);
            }
            if (this.addressObj.cityId) {
                this.findBackendJdRegion("countyList", this.addressObj.cityId);
            }
            if (this.addressObj.countyId) {
                this.findBackendJdRegion("townList", this.addressObj.countyId);
            }
        },
        async findBackendJdRegion(type, id) {
            let {data} = await findBackendJdRegionApi({
                id
            })
            this.addressList[type] = data
        },
        /**
         * 设置发货地址
         */
        async setJdAddress() {
            if (this.addressObj.provinceId) {
                localStorage.setItem("provinceId", this.addressObj.provinceId)
            }
            if (this.addressObj.cityId) {
                localStorage.setItem("cityId", this.addressObj.cityId)
            }
            if (this.addressObj.countyId) {
                localStorage.setItem("countyId", this.addressObj.countyId)
            }
            if (this.addressObj.townId) {
                localStorage.setItem("townId", this.addressObj.townId)
            }
            this.addressDialog = false
            this.$message({
                type: 'success',
                message: '保存成功!'
            });
            this.getList();
        },
        /**
         * 导出文件
         */
        async exportExcel() {
            this.excelLoading = true;
            const response = await exportExcelApi({
                topicId: this.item.id,
                current: 1,
                size: 10,
                isJd: 1
            })
            const blob = new Blob([response], {type: 'application/octet-stream'});
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${sd.format(new Date())}_已选商品.xlsx`; // 设置下载的文件名，根据需要修改
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            this.excelLoading = false;
        },
        /**
         * 批量删除商品
         */
        batchDelGoods() {
            this.$confirm('确定删除已选商品', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                for (const e of this.idArr) {
                    await deleteGoodsApi({
                        goodsId: e,
                        topicId: this.item.id,
                        removeType: 1,
                        isJd: 1
                    })
                }
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });
                this.queryGetList();
            })
        },
        /**
         * 删除已选商品
         */
        async delGoods(item,removeType) {
            if (removeType === 1) {
                await this.$confirm('确定删除当前库中该商品', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async () => {
                    await deleteGoodsApi({
                        goodsId: item.id,
                        topicId: this.item.id,
                        removeType: removeType,
                        isJd: item.isJd
                    })
                })
            } else if (removeType === 2) {
                await this.$confirm('确定删除所有库中该商品', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async () => {
                    await deleteGoodsApi({
                        goodsId: item.id,
                        topicId: this.item.id,
                        removeType: removeType,
                        isJd: item.isJd
                    })
                })
            }
            /*await deleteGoodsApi({
                goodsId: item.id,
                topicId: this.item.id
            })
            ;*/
            this.$message({
                type: 'success',
                message: '删除成功!'
            });
            this.queryGetList();
        },
        /**
         * 获取选择商品ID
         * @param val
         */
        handleSelectionChange(val) {
            this.idArr = val.map(item => item.id)
        },
        /**
         * 修改排序
         */
        setSort(item) {
            this.$prompt('请输入排序号', '修改排序', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputValue: item.sort
            }).then(async ({value}) => {
                let obj = {
                    goodsId: item.id,
                    sort: value,
                    topicId: this.item.id
                }
                await updateSortApi(JSON.stringify(obj));
                this.queryGetList()
            })
        },
        async setTopicGoodsDisPrice(item) {
            this.$prompt('请输入排序号', '修改排序', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputValue: item.disPrice
            }).then(async ({value}) => {
                let obj = {
                    goodsId: item.id,
                    disPrice: value,
                    disPriceType: 1,
                    topicId: this.item.id
                }
                await updateTopicGoodsDisPriceApi(JSON.stringify((obj)));
                this.$message({
                    type: 'success',
                    message: '修改成功!'
                });
                this.queryGetList()
            })
        },
        /**
         * 清空选品库
         */
        clearSelGoods() {
            this.$confirm('确定清空当前选品库吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let {data} = await clearTopicApi({
                    id: this.selectionGoods.topicId
                })
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });
                this.queryGetList();
            })
        },
        /**
         * 切换列表
         */
        switchGoods(index) {
            this.index = index;
            if (index === 1) {
                this.queryGetList();
            } else {
                this.$emit("SwithGoods", index)
            }
        },
        /**
         * 分页大小切换时触发
         * @param val
         */
        handleSizeChange(val) {
            this.selectionGoods.size = val;
            this.getList();
        },
        /**
         * 页码切换时触发
         * @param val
         */
        handleCurrentChange(val) {
            this.selectionGoods.current = val;
            this.getList();
        },
        /**
         * 评论类型
         */
        commentType() {
            return commentType
        },
        /**
         * 条件查询
         */
        queryGetList() {
            this.getList()
        },

        /**
         * 查询
         */
        async getList() {
            this.loading = true;
            let config = {
                ...this.selectionGoods,
                // sortOrder:this.selectionGoods.sortOrder ? this.selectionGoods.sortOrder.join(',') : ''
            }
            let obj = JSON.parse(JSON.stringify(config));
            ObjectUtil.removeEmptyValues(obj)
            let {data: res} = await getSelectedPageApi(obj)

            this.skuListFiler = []
            /*if (localStorage.getItem("provinceId")) {
                let list = []
                res.rows.forEach(r => {
                    list.push(r.skuId)
                })
                this.addressObj.provinceId = localStorage.getItem("provinceId") || ""
                this.addressObj.cityId = localStorage.getItem("cityId") || ""
                this.addressObj.countyId = localStorage.getItem("countyId") || ""
                this.addressObj.townId = localStorage.getItem("townId") || ""
                let {data} = await getGoodsDetailByIdsApi({
                    provinceId: this.addressObj.provinceId,
                    cityId: this.addressObj.cityId,
                    countyId: this.addressObj.countyId,
                    townId: this.addressObj.townId,
                    skuIdList: list.join(",")
                })
                data.forEach((r, index) => {
                    let arr = JSON.parse(r);
                    arr.forEach((item) => {
                        res.rows[index].addressStatus = item.areaRestrict
                    })
                })
            }*/
            this.selectGoods = res.rows;
            let {data} = await getSelectedTotalApi(obj)
            this.total = data
            this.loading = false;
        },
        /**
         * 初始化组件
         */
        open(item) {
            this.item = item;
            this.selectionGoods.current = 1;
            this.selectionGoods.topicId = item.id;
            this.dialog = true;
            this.initCategory(item.id);
            this.queryGetList()
        },
        /**
         * 初始化分类
         */
        async initCategory(topicId) {
            // let {data} = await getCategoryListApi();
            let {data} = await getCategoryListByTopicApi({ topicId: topicId});
            this.categoryId1 = data.categoryList;
        },
        /**
         * 获取二级分类
         */
        async getCategory1List() {
            // let {data} = await getCategory1ListApi({
            //     name: this.selectionGoods.categoryId1Name, categoryLevel: 1
            // });
            let {data} = await getCategory1ListByTopicApi({
                name: this.selectionGoods.categoryId1Name, categoryLevel: 1, topicId: this.item.id
            });
            this.selectionGoods.categoryId2Name = ""
            this.categoryId2 = data.categoryList;
            this.queryGetList()
        },
        /**
         * 切换商品货源
         */
        isJdChange() {
            this.selectionGoods.current = 1;
            this.queryGetList()
        },
        /**
         * 重置
         */
        reset() {
            this.selectionGoods = {
                commentType: "",
                goodsId: "",
                current: 1,
                size: 10,
                total: 999,
                isJd: 1,
                title: "",
                categoryId1Name: "",
                categoryId2Name: "",
                level: "",
                phone: "",
                minSupplyPrice: "",
                maxSupplyPrice: "",
                minProfitMargin: "",
                maxProfitMargin: "",
            }
            this.queryGetList();
        }
    },
    mounted() {
        this.open(this.items);
    }
}