<template>
  <div>
    <div class="post d-flex flex-column-fluid" id="kt_post">
      <div id="kt_content_container"
           style="margin-top:10px;max-width:100%;flex-direction:row!important;display:flex; flex-wrap: wrap;">
        <!-- 搜索开始 -->
        <div style="width: 100%">
          <div class="card">
            <div class="card-body" style="padding: 5px 5px !important;">
              <div class="col-lg-2 me-2">
                <label class="fs-4 form-label fw-bold">商品池</label>
                <br/>
                <el-select v-model="paramss.type" placeholder="请选择" @change="shaixuan">
                  <el-option v-for="item in optionss" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <div class="col-lg-2" v-if="paramss.type==2">
                <label class="fs-4 form-label fw-bold">商品来源</label>
                <br/>
                <el-select v-model="paramss.isJd" placeholder="请选择" @change="shaixuan">
                  <el-option v-for="item in jdType" :key="item.type" :label="item.name"
                             :value="item.type">
                  </el-option>
                </el-select>
              </div>
              <div class="col-lg-7" v-if="paramss.type==2">
                <label class="fs-4 form-label fw-bold">收货地址</label>
                <br/>
                <span>省：</span>
                <el-select class="me-2" v-model="paramss.provinceId" clearable placeholder="请选择省" @visible-change="getAddressList(0,0)">
                  <el-option v-for="(item,index) in provinceList" :key="index" :label="item.name"
                             :value="`${item.kid}`"></el-option>
                </el-select>
                <span>市：</span>
                <el-select class="me-2" v-model="paramss.cityId" clearable placeholder="请选择市" :disabled="!paramss.provinceId"
                           @visible-change="getAddressList(paramss.provinceId,1)">
                  <el-option v-for="(item,index) in cityList" :key="index" :label="item.name"
                             :value="item.kid"></el-option>
                </el-select>
                <span>区/县：</span>
                <el-select class="me-3" v-model="paramss.countyId" clearable placeholder="请选择区/县" :disabled="!paramss.cityId"
                           @visible-change="getAddressList(paramss.cityId,2)">
                  <el-option v-for="(item,index) in countyList" :key="index" :label="item.name"
                             :value="item.kid"></el-option>
                </el-select>
                <el-button size="small" type="primary" plain @click="clearAddress">地址清空</el-button>
              </div>

              <div class="col-lg-2 form-label fw-bold" style="margin-top: 10px !important;">
                <!--<span class="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ms-6">
                  <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px"
                       xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                      <rect height="24" width="24" x="0" y="0"></rect>
                      <path
                          d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                          fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                      <path
                          d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                          fill="#000000" fill-rule="nonzero"></path>
                    </g>
                  </svg>
                </span>-->
                <!--<input id="sTitle" v-model="paramss.title" class="form-control form-control-solid ps-10"
                       name="shopName" placeholder="商品名称" type="text" value=""/>-->
                商品名称:
                <el-autocomplete
                    class="ps-4"
                    v-model="paramss.title"
                    placeholder="请输入内容"
                    @select="handleSelect"
                ></el-autocomplete>
              </div>

              <div class="col-lg-2" style="width: 13%;margin-top: 10px">
                <label class="form-label fw-bold">一级分类：</label>
                <el-select style="width: 65%" clearable v-model="paramss.categoryId1" placeholder="请选择" @change="getCategory(1)">
                  <el-option v-for="item in categoryId1" :key="item.categoryId" :label="item.fullName"
                             :value="item.categoryId">
                  </el-option>
                </el-select>
              </div>
              <div class="col-lg-2" style="width: 13%;margin-top: 10px">
                <label class="form-label fw-bold">二级分类：</label>
                <el-select style="width: 65%" clearable v-model="paramss.categoryId2" placeholder="请选择" @change="getCategory(2)">
                  <el-option v-for="item in categoryId2" :key="item.categoryId" :label="item.fullName"
                             :value="item.categoryId">
                  </el-option>
                </el-select>
              </div>

              <div class="col-lg-2" style="width: 13%;margin-top: 10px">
                <label class="form-label fw-bold">配送模板：</label>
                <el-select style="width: 65%" clearable v-model="paramss.logisticsType" placeholder="请选择" @change="getCategory(2)">
                  <el-option v-for="item in logisticsItem" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>

              <div class="col-lg-4" style="margin-top: 10px">
                <label class="form-label fw-bold">库存最低发货数量：</label>
                <el-input style="width: 30% !important;" v-model="paramss.stockCheck" type="number" maxlength="11" show-word-limit></el-input>
              </div>

              <div class="col-lg-1" v-if="paramss.type == 1">
                <label class="fs-6 form-label fw-bolder text-dark"
                >库存区间</label
                >
                <div class="row">
                  <div class="col-lg-6 fv-row fv-plugins-icon-container">
                    <input
                        type="text" id="minPoolSupplPrice" name="minPoolSupplPrice"
                        class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        v-model="paramss.minStock" placeholder="最小值" value=""
                        oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                    />
                    <div
                        class="fv-plugins-message-container invalid-feedback"
                    ></div>
                  </div>
                  <div class="col-lg-6 fv-row fv-plugins-icon-container">
                    <input
                        type="text"
                        id="maxPoolSupplPrice"
                        name="maxPoolSupplPrice"
                        class="form-control form-control-lg form-control-solid"
                        v-model="paramss.maxStock"
                        oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                        placeholder="最大值"
                        value=""
                    />
                    <div
                        class="fv-plugins-message-container invalid-feedback"
                    ></div>
                  </div>
                </div>
              </div>

              <div class="col-lg-2" v-if="paramss.type == 2" style="margin-top: 10px">
                <label class="fs-6 form-label fw-bolder text-dark"
                >分销价区间</label
                >
                <div class="row">
                  <div class="col-lg-6 fv-row fv-plugins-icon-container">
                    <input
                        type="text" id="minPoolSupplPrice" name="minPoolSupplPrice"
                        class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        v-model="paramss.minPoolSupplPrice" placeholder="最小值" value=""
                        oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                    />
                    <div
                        class="fv-plugins-message-container invalid-feedback"
                    ></div>
                  </div>
                  <div class="col-lg-6 fv-row fv-plugins-icon-container">
                    <input
                        type="text"
                        id="maxPoolSupplPrice"
                        name="maxPoolSupplPrice"
                        class="form-control form-control-lg form-control-solid"
                        v-model="paramss.maxPoolSupplPrice"
                        oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                        placeholder="最大值"
                        value=""
                    />
                    <div
                        class="fv-plugins-message-container invalid-feedback"
                    ></div>
                  </div>
                </div>
              </div>

              <div class="col-lg-2" v-if="paramss.type == 2 && paramss.isJd == 1" style="margin-top: 10px">
                <label class="fs-6 form-label fw-bolder text-dark"
                >评论区间</label
                >
                <div class="row">
                  <div class="col-lg-6 fv-row fv-plugins-icon-container">
                    <input
                        type="text" id="minNumberOfComments" name="minNumberOfComments"
                        class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        v-model="paramss.minNumberOfComments" placeholder="最小值" value=""
                        oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                    />
                    <div
                        class="fv-plugins-message-container invalid-feedback"
                    ></div>
                  </div>
                  <div class="col-lg-6 fv-row fv-plugins-icon-container">
                    <input
                        type="text"
                        id="maxNumberOfComments"
                        name="maxNumberOfComments"
                        class="form-control form-control-lg form-control-solid"
                        v-model="paramss.maxNumberOfComments"
                        oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                        placeholder="最大值"
                        value=""
                    />
                    <div
                        class="fv-plugins-message-container invalid-feedback"
                    ></div>
                  </div>
                </div>
              </div>

<!--              <div class="col-lg-3" style="margin-top: 10px">
                <label class="fs-6 form-label fw-bolder text-dark"
                >利润率区间</label
                >
                <div class="row">
                  <div class="col-lg-6 fv-row fv-plugins-icon-container">
                    <input
                        v-if="paramss.type==1"
                        type="text"
                        id="minRightRate"
                        name="minRightRate"
                        class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        v-model="paramss.minRightRate"
                        oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                        placeholder="最小值"
                        value=""
                    />
                    <input
                        v-if="paramss.type==2"
                        type="text"
                        id="minQuanRightRate"
                        name="minQuanRightRate"
                        class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        v-model="paramss.minQuanRightRate"
                        oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                        placeholder="最小值"
                        value=""
                    />
                    <div
                        class="fv-plugins-message-container invalid-feedback"
                    ></div>
                  </div>
                  <div class="col-lg-6 fv-row fv-plugins-icon-container">
                    <input
                        v-if="paramss.type==1"
                        type="text"
                        id="maxRightRate"
                        name="maxRightRate"
                        class="form-control form-control-lg form-control-solid"
                        v-model="paramss.maxRightRate"
                        oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                        placeholder="最大值"
                        value=""
                    />
                    <input
                        v-else
                        type="text"
                        id="maxQuanRightRate"
                        name="maxQuanRightRate"
                        class="form-control form-control-lg form-control-solid"
                        v-model="paramss.maxQuanRightRate"
                        oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                        placeholder="最大值"
                        value=""
                    />
                    <div
                        class="fv-plugins-message-container invalid-feedback"
                    ></div>
                  </div>
                </div>
              </div>-->

              <div class="col-lg-1 btn btn-primary me-5 " style="width: 5%; margin-top: 25px !important;" @click="shaixuan">
                查询
              </div>

              <div class="col-lg-1 btn btn-secondary me-5" style="width: 5%; margin-top: 25px !important;" @click="resetBtn">
                重置
              </div>

              <div class="col-lg-2 btn btn-success me-5" @click="openLink" v-loading="linkLoading" element-loading-text="拼命加载中"
                   element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)" style="width: 10%; margin-top: 25px !important;">
                打开此列商品京东链接
              </div>
            </div>
          </div>
        </div>

        <div class="card mb-5 mb-xl-8" style="margin-left:3px;width:100%;" v-loading="loading" element-loading-text="拼命加载中"
             element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
          <table class="table align-middle table-row-dashed table-hover  dataTable no-footer">
            <thead>
            <tr class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
              <th class="min-w-300px" tabindex="0" aria-controls="kt_ecommerce_products_table" rowspan="1" colspan="1"
                  aria-label="Product: activate to sort column ascending" style="width: 212.141px">
                商品信息
              </th>
              <th>
                分类
              </th>
              <th class="text-center">
                市场价
              </th>
              <th class="text-center">
                分销价
              </th>
              <th class="text-center">
                销售价
              </th>
              <th class="text-center">
                利润率
              </th>
              <th class="text-center">
                配送模板
              </th>
              <th class="text-center">
                库存
              </th>
              <th class="text-center">
                商品来源
              </th>
              <th class="text-center">
                好评数
              </th>
              <th class="text-center">
                起购数量
              </th>
              <th class="text-center">
                能否发货
              </th>
              <th class="text-center sorting_disabled" rowspan="1" colspan="1" aria-label="操作" style="width: 100px;">
                操作
              </th>
            </tr>
            </thead>
            <tbody class="fw-semibold text-gray-600">
            <tr v-for="(item, index) in selGoodsList" :class="{ old: index % 2 == 0, even: index % 2 != 0 }"
                :key="index">
              <td>
                <div class="d-flex align-items-center">
                  <!--<a href="javascript:void(0);" class="symbol symbol-50px">
                    <img class="symbol-label lozad" v-if="item.picUrl.indexOf('http') == 0" :src="`${item.picUrl}`"/>
                    <img class="symbol-label lozad" v-else :src="`https://www.1c-1g.cn${item.picUrl}`"/>
                  </a>-->
                  <div class="ms-5" v-if="item.picUrl.indexOf('/Uploads') ==0">
                    <div class="d-flex align-items-center">
                      <div class="symbol symbol-50px me-5">
                        <img :src="`https://www.1c-1g.cn${item.picUrl}`"
                             alt="" class="">
                      </div>
                      <div
                          class="d-flex justify-content-start flex-column">
                        <a :href="`${item.jdUrl}`"
                           class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                           target="_self">{{ item.title }}</a>
                      </div>
                    </div>
                  </div>
                  <div class="ms-5" v-else>
                    <div class="d-flex align-items-center">
                      <div class="symbol symbol-50px me-5">
                        <img :src="item.picUrl">
                      </div>
                      <div
                          class="d-flex justify-content-start flex-column">
                        <a :href="`https://item.jd.com/${item.skuId}.html`"
                           class="text-gray-800 text-hover-primary fs-5"
                           data-kt-ecommerce-product-filter="product_name"
                           target="_blank">{{ item.title }}</a>
                      </div>
                    </div>
                  </div>
                  <!--<div class="ms-5">
                    <a href="" class="text-gray-800 text-hover-primary fs-5"
                       data-kt-ecommerce-product-filter="product_name">{{ item.title }}</a>
                  </div>-->
                </div>
              </td>
              <td>
                <div v-html="item.categoryName"></div>
              </td>
              <td class="text-center">{{ item.price | format }}</td>
              <td class="text-center">{{ item.supplPrice | format }}</td>
              <td class="text-center" v-if="paramss.type==1">{{ item.salePrice | format }}</td>
              <td class="text-center" v-if="paramss.type==2 && item.isJD == 1">{{ item.salePrice | format }}</td>
              <td class="text-center" v-html="item.rightRate" v-if="paramss.type==1"></td>
              <td class="text-center" v-html="item.maxRightRate" v-else></td>
              <td class="text-center" v-if="paramss.type==1">{{ item.freightId }}</td>
              <td class="text-center" v-else-if="paramss.type==2 && item.isJD == 2">厂供模板</td>
              <td class="text-center text-success" v-else-if="paramss.type==2 && item.isJD == 1 && item.logisticsType === 1">京东配送</td>
              <td class="text-center" v-else-if="paramss.type==2 && item.isJD == 1 && item.logisticsType === 0">厂供配送</td>
              <td class="text-center" v-else-if="paramss.type==2 && item.isJD == 1 && item.logisticsType === 2">厂供配送</td>
              <td class="text-center" v-if="paramss.type==1">{{ item.stock || 0 }}</td>
              <td class="text-center" v-else-if="paramss.type==2 && item.isJD == 2">{{ item.stock || 0 }}</td>
              <td class="text-center text-success" v-else-if="paramss.type==2 && item.isJD == 1
                && item.deliveryAddressStockStatus === 33">该地区有货
              </td>
              <td class="text-center text-danger" v-else-if="paramss.type==2 && item.isJD == 1
                && item.deliveryAddressStockStatus === 34">该地区无货
              </td>
              <td class="text-center text-warning" v-else-if="paramss.type==2 && item.isJD == 1
                && item.deliveryAddressStockStatus === 36">该地区需要预定
              </td>
              <td class="text-center text-primary" v-else-if="paramss.type==2 && item.isJD == 1
                && item.deliveryAddressStockStatus === -1">请选择地址
              </td>
              <td class="text-center text-primary" v-else-if="paramss.type==2 && item.isJD == 1
                && item.deliveryAddressStockStatus === 2004">不在用户商品池
              </td>
              <td class="text-center" v-if="paramss.type==1">{{ item.type }}</td>
              <td class="text-center" v-else-if="paramss.type==2 && item.isJD == 1">京东</td>
              <td class="text-center" v-else-if="paramss.type==2 && item.isJD == 2">自有</td>
              <td class="text-center" v-if="item.isJD == 1">{{ item.commentDefaultCount || 0 }}</td>
              <td class="text-center">{{ item.buyType }}</td>
              <td class="text-center text-success" v-if="item.deliveryAddressStatus == 1">可发货</td>
              <td class="text-center text-danger" v-if="item.deliveryAddressStatus == 2">不可发货</td>
              <td class="text-center text-primary" v-if="item.deliveryAddressStatus == 999">请选择地址</td>
              <!-- 操作 -->
              <td class="text-center">
              </td>
            </tr>
            </tbody>
          </table>
          <el-pagination
              class="table-responsive" v-loading="pageLoading" element-loading-text="拼命加载中"
              element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)"
              ref="pagination"
              style="margin-top: 10px;text-align: center"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="paramss.current"
              :page-sizes="[10, 30, 50, 100, 500, 1000]"
              :page-size="paramss.size"
              layout="total, sizes, prev, pager, next, jumper"
              :total="totalCount">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {getCategoryList} from "@/api/backend/categoryApi";
import {getOptimalSelectionGoodsApi, getOptimalSelectionGoodsCountApi} from "@/api/backend/chooseGoodsApi";
import {findBackendJdRegionApi} from "@/api/backend/addressApi";



export default {
  data() {
    return {
      // 请求参数
      paramss: {
        isTwoShow: 1,
        telNum: "",
        title: "", // 京东是sTitle 自营是title
        type: "2",
        categoryId1: "",
        categoryId2: "",
        size: 10,
        current: 1,
        isJd: 1,
        maxPrice: "",
        minPrice: "",
        minRightRate: "",
        maxRightRate: "",
        minQuanRightRate: "",
        maxQuanRightRate: "",
        minPoolSupplPrice: "",
        maxPoolSupplPrice: "",
        maxStock: "",
        minStock: "",
        cityId: "",
        provinceId: "",
        countyId: "",
        stockCheck: "",
        logisticsType: "",
        minNumberOfComments: "",
        maxNumberOfComments: "",

      },
      idArr: [],
      categoryId1: [],
      categoryId2: [],
      selGoodsList: [],
      goodsListDialog: false,
      totalCount: 0,
      hidenCategory: true,
      goodsListHiden: true,
      logisticsItem: [
        {
          value: '1',
          label: '京东配送'
        },
        {
          value: '2',
          label: '厂供配送'
        }
      ],
      // 商品池
      optionss: [
        {
          value: '1',
          label: '自有商品池'
        },
        {
          value: '2',
          label: '全国商品池'
        }
      ],
      // 商品来源
      jdType: [
        {
          type: 1,
          name: "京东商品"
        },
        //{
        //  type: 2,
        //  name: "厂供商品"
        //}
      ],
      rows: [],
      loading: false,
      ids: [],
      id: "",
      pageLoading: true,
      first: true,
      linkLoading: false,
      /*收货地址*/
      countyList: [],
      cityList: [],
      provinceList: [],

    };
  },
  // 方法
  methods: {
    /**
     * 分页大小切换时触发
     * @param val
     */
    handleSizeChange(val) {
      this.paramss.size = val;
      this.findGoodsList();
    },
    /**
     * 页码切换时触发
     * @param val
     */
    handleCurrentChange(val) {
      this.paramss.current = val;
      this.findGoodsList();
    },
// 数据筛选
    shaixuan() {
      this.first = true;
      this.paramss.current = 1
      this.handleCurrentChange(1)
      //this.findGoodsList()
    },
    // 筛选数据清空
    resetBtn() {
      this.first = true;
      this.paramss.isTwoShow = ""
      if (this.paramss.isJd == 2) {
        this.paramss.isTwoShow = 1
      }
      this.paramss = {
        telNum: "",
        title: "",
        isTwoShow: this.paramss.isTwoShow,
        type: this.paramss.type,
        categoryId1: "",
        categoryId2: "",
        size: 10,
        current: 1,
        isJd: this.paramss.isJd,
        maxPrice: "",
        minPrice: "",
        minRightRate: "",
        maxRightRate: "",
        minQuanRightRate: "",
        maxQuanRightRate: "",
        minPoolSupplPrice: "",
        maxPoolSupplPrice: "",
        maxStock: "",
        minStock: "",
      }
      this.categoryId2 = []
      this.findGoodsList()
    },
    // 初始化分类
    async initCategory() {
      let {data} = await getCategoryList({
        parentId: 0
      })
      this.categoryId1 = data.list
    },
    // 查询分类数据
    async getCategory(item) {
      this.first = true;
      if (item === 1) {
        this.paramss.categoryId2 = ""
        let parentId = this.paramss.categoryId1
        let {data} = await getCategoryList({
          parentId
        })
        this.categoryId2 = data.list
      }
      this.findGoodsList()
    },
    //  标题搜索
    handleSelect(item) {
      this.first = true;
      this.paramss.isTwoShow = ""
      if (this.paramss.isJd == 2) {
        this.paramss.isTwoShow = 1
      }
      this.paramss = {
        telNum: "",
        title: item.value,
        isTwoShow: this.paramss.isTwoShow,
        type: this.paramss.type,
        categoryId1: "",
        categoryId2: "",
        size: 10,
        current: 1,
        isJd: this.paramss.isJd,
        maxPrice: "",
        minPrice: "",
        minRightRate: "",
        maxRightRate: "",
        minQuanRightRate: "",
        maxQuanRightRate: "",
        minPoolSupplPrice: "",
        maxPoolSupplPrice: "",
        maxStock: "",
        minStock: "",
      }
      this.categoryId2 = []
      this.findGoodsList();
    },
    // 查询商品数据
    async findGoodsList() {
      if (this.paramss.minStock || this.paramss.maxStock
          || this.paramss.minRightRate || this.paramss.maxRightRate) {
        this.paramss.maxPoolSupplPrice = ""
        this.paramss.minPoolSupplPrice = ""
        this.paramss.maxQuanRightRate = ""
        this.paramss.minQuanRightRate = ""
      }
      if (this.paramss.maxPoolSupplPrice || this.paramss.minPoolSupplPrice ||
          this.paramss.maxQuanRightRate || this.paramss.minQuanRightRate) {
        this.paramss.minStock = ""
        this.paramss.maxStock = ""
        this.paramss.minRightRate = ""
        this.paramss.maxRightRate = ""
      }
      /*if (this.paramss.maxQuanRightRate ) {
        this.paramss.maxQuanRightRate = (this.paramss.maxQuanRightRate / 100).toFixed(4)
      }
      if (this.paramss.minQuanRightRate) {
        this.paramss.minQuanRightRate = (this.paramss.minQuanRightRate / 100).toFixed(4)
      }*/

      if (this.paramss.type == 1) {
        this.paramss.isJd = 2;
        this.loading = true;
        this.paramss.isTwoShow = 1;
        this.paramss.isShow = 1;
        let {data} = await getSjGoodsListApi(this.paramss)
        this.selGoodsList = data.rows;
        this.loading = false;
        if (this.first) {
          let {data} = await getSjGoodsListCountApi(this.paramss)
          this.totalCount = data
        }
      } else {
        this.paramss.isJd = 1;
        this.loading = true;
        this.pageLoading = true;
        let json = JSON.parse(JSON.stringify(this.paramss))
        json.sTitle = json.title;
        delete json.title;
        /*let {data} = await getESGoodsListApi(json)
        this.totalCount = data.total*/

        let {data} = await getOptimalSelectionGoodsApi(json)
        this.loading = false;
        this.selGoodsList = data.rows;

        if (this.first) {
          let {data} = await getOptimalSelectionGoodsCountApi(json)
          this.totalCount = data
        }
        this.pageLoading = false;
      }
      this.first = false
    },
    // 打开商品的京东链接
    openLink() {
      this.linkLoading = false;
      // 获取 selGoodsList 中的 skuId 列表
      var skuIds = this.selGoodsList.map(item => item.skuId);
// 定义基础链接
      var baseUrl = 'https://item.jd.com/';
// 创建链接列表
      var links = skuIds.map(skuId => baseUrl + skuId + '.html');
// 在新窗口打开链接
//      links.forEach(link => window.open(link, '_blank'));
      var count = 0; // 计数已经打开的链接次数
      var maxCount = 50; // 最大打开链接次数
      var that = this;

      function openLinks(links) {
        count++;
        if (links.length === 0) {
          return;
        }
        var link = links.shift(); // 从数组中取出第一个链接
        var newWindow = window.open(link, '_blank'); // 在新窗口打开链接
        newWindow.location = link;
        if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
          // 如果浏览器阻止了弹出窗口，则终止打开链接操作
          return;
        }
        // 休眠次数
        console.log("最大限制" + count)
        if (count == maxCount) {
          console.log("到达休眠范围  开始休眠")
          count = 0;
          var randomNum = Math.floor(Math.random() * 1200000) + 420000;//分钟
          // 休眠十分钟
          setTimeout(function () {
            console.log('休眠结束');
          }, randomNum);
        }
        var randomDelay = Math.floor(Math.random() * 30000) + 10000; // 生成 9000 到 15000 毫秒之间的随机时间 秒
        var randomClose = Math.floor(Math.random() * 10000) + 1000; // 生成 1000 到 5000 毫秒之间的随机时间
        setTimeout(function () {
          window.close();
          newWindow.close();
        }, randomClose);
        //window.close();
        //newWindow.close();
        setTimeout(function () {
          openLinks(links); // 递归调用，打开下一个链接
        }, randomDelay); // 设置随机间隔时间
      }

      openLinks(links); // 调用函数开始打开链接
      window.focus();
      this.linkLoading = false
    },
    /**
     * 获取地址
     */
    async getAddressList(id, type) {
      console.log(this.paramss.provinceId)
      let {data} = await findBackendJdRegionApi({
        id
      })
      if (type == 0) {
        this.provinceList = data
      } else if (type == 1) {
        this.cityList = data
      } else if (type == 2) {
        this.countyList = data
      }
    },
    // 清楚地址信息
    clearAddress() {
      this.paramss.provinceId = "";
      this.paramss.cityId = "";
      this.paramss.countyId = "";
    },
  },

  // 初始化这个方法
  created() {
    this.findGoodsList();
    this.initCategory();
    this.getAddressList(0, 0);
  },

};
</script>
<!--组件样式-->
<style scoped>

</style>