<template>
  <div>
    <div v-loading="loading"
         element-loading-background="rgba(0, 0, 0, 0.8)"
         element-loading-spinner="el-icon-loading"
         element-loading-text="列表加载中..."
         style="max-width: 97%; margin: 10px auto;background: #fff;border-radius: 14px;min-height: 87vh;padding: 10px"
    >
      <div style="display: flex;justify-content: flex-start;align-items: center;">

        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-input v-model.trim="params.cardNum" placeholder="请输入需要查询的卡号"
                    size="mini" style="width: 200px"></el-input>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-input v-model.trim="params.startCardNum" placeholder="请输入需要查询的起始卡号"
                    size="mini" style="width: 200px"></el-input>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-input v-model.trim="params.endCardNum" placeholder="请输入需要查询的结束卡号"
                    size="mini" style="width: 200px"></el-input>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-select v-model="params.status" placeholder="请选择状态" size="mini" @change="getCardItemList">
            <el-option value="" label="全部"></el-option>
            <el-option v-for="(item,index) in statusList" :key="index" :label="item.label"
                       :value="`${item.value}`"></el-option>
          </el-select>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-date-picker
              v-model="value2"
              :picker-options="pickerOptions"
              align="right"
              end-placeholder="结束开通日期"
              range-separator="至"
              size="mini"
              start-placeholder="开始开通日期"
              type="datetimerange">
          </el-date-picker>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-button size="mini" type="primary" @click="getCardItemList">搜索</el-button>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-button size="mini" @click="resetListParams">重置</el-button>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-button size="mini" @click="exportXiTunBaoCardItemList">导出</el-button>
        </div>
        <div style="margin-left: 10px;margin-bottom: 10px">
          <el-dropdown trigger="click">
            <el-button size="mini" type="primary">
              批量操作 <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="bactchRemark" icon="el-icon-circle-plus">批量修改备注</el-dropdown-item>
              <el-dropdown-item @click.native="batchStatus">批量修改状态</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <!--          <el-button size="mini" @click="bactchRemark">批量修改备注</el-button>-->
        </div>

      </div>
      <div style="margin-left: 10px;margin-bottom: 10px">
        使用者ID：
        <el-input v-model.trim="params.useUserId" placeholder="请输入使用者ID:"
                  size="mini" style="width: 200px"></el-input>
      </div>
      <el-table
          :data="rows"
          @selection-change="handleSelectionChange"
          style="width: 100%">
        <el-table-column
            type="selection"
            width="55">
        </el-table-column>
        <el-table-column
            fixed
            label="ID"
            prop="id"
            width="60">
        </el-table-column>
        <el-table-column
            width="80"
            label="卡类型">
          <template slot-scope="{row}">
            {{ row.cardType | cardTypeF }}
          </template>
        </el-table-column>
        <el-table-column
            width="110"
            label="卡号">
          <template slot-scope="{row}">
            {{ row.cardNum }}
          </template>
        </el-table-column>

        <el-table-column
            width="200"
            label="备注">
          <template slot-scope="{row}">
            {{ row.remark }}
          </template>
        </el-table-column>

        <el-table-column
            width="120"
            label="状态">
          <template slot-scope="{row}">
            {{ row.status | statusF }}
          </template>
        </el-table-column>
        <el-table-column
            label="手机号"
            width="160">
          <template slot-scope="{row}">
            {{ row.phone }}
          </template>
        </el-table-column>

        <el-table-column
            label="生成时间"
            width="160">
          <template slot-scope="{row}">
            {{ `${row.createTime}000` | formatDate }}
          </template>
        </el-table-column>

        <el-table-column
            label="兑换时间"
            width="160">
          <template slot-scope="{row}">
            <div v-if="row.receiveTime">
              {{ `${row.receiveTime}000` | formatDate }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
            label="开通时间"
            width="160">
          <template slot-scope="{row}">
            <div v-if="row.openTime">
              {{ `${row.openTime}000` | formatDate }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
            label="到期时间"
            width="160">
          <template slot-scope="{row}">
            <div v-if="row.expiryTime">
              {{ `${row.expiryTime}000` | formatDate }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
            width="140"
            label="卡密">
          <template slot-scope="{row}">
            {{ row.cdkey }}
          </template>
        </el-table-column>
        <el-table-column
            width="80"
            label="面值">
          <template slot-scope="{row}">
            {{ row.faceValue }}
          </template>
        </el-table-column>
        <el-table-column
            width="80"
            label="来源">
          <template slot-scope="{row}">
            {{ row.cardOrigin | cardOriginF }}
          </template>
        </el-table-column>
        <el-table-column
            label="卡属性"
            width="110">
          <template slot-scope="{row}">
            {{ row.limitation | limitationF }}
          </template>
        </el-table-column>
        <el-table-column
            label="物流单号">
          <template slot-scope="{row}">
            {{ row.logisticsOrderNo }}
          </template>
        </el-table-column>
        <el-table-column
            fixed="right"
            label="操作"
            width="320px">
          <template slot-scope="{row}">
            <el-button size="mini" @click="editlogisticsOrderNo(row)">编辑物流单号</el-button>
            <el-button size="mini" @click="editRemark(row)">编辑备注</el-button>
            <el-button size="mini" @click="cdKeyStatus = true; cardData = row">修改状态</el-button>
          </template>
        </el-table-column>

      </el-table>
      <el-pagination
          :page-size="0"
          :page-sizes="[10, 20, 30, 50]"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          style="margin-top: 10px;text-align: center"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange">
      </el-pagination>
    </div>

    <el-dialog :visible.sync="cdKeyStatus" title="修改卡片状态" top="50px" width="40%" append-to-body>
      <div>
        <el-button size="mini" type="danger" @click="confirmEditcdKeyStatus(-1)">未开通</el-button>
        <el-button size="mini" type="success" @click="confirmEditcdKeyStatus(0)">已开通</el-button>
        <el-button size="mini" type="primary" @click="confirmEditcdKeyStatus(1)">已发货</el-button>
        <el-button size="mini" type="warning" @click="confirmEditcdKeyStatus(4)">已退款</el-button>
      </div>
      <label> 开通期限(开通需要填写数字，表示月份)：</label>
      <div>
        <el-tooltip class="item inline" effect="dark" content="开通需要填写数字，表示月份" placement="top">
          <el-input v-model="openDuration" size="mini" style="width: 20% !important; margin-bottom: 0; !important;"></el-input>
        </el-tooltip>
      </div>
    </el-dialog>

  </div>
</template>
<script>

import {
  editCardStatusApi,
  editKeyRemarkApi,
  editLogisticsOrderNoApi,
  exportXiTunBaoCardItemListApi,
  getCardUserItemListApi,
  getXiTunBaoCardNameListApi
} from "@/api/backend/xitunbaoApi";
import sd from "silly-datetime";

export default {
  name: 'keyList',
  data() {
    return {
      cardData: {},
      cdKeyStatus: false,
      item: {},
      loading: false,
      loadingMsg: "列表加载中...",
      size: 10,
      current: 1,
      multipleSelection: [],
      total: 0,
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      params: {
        useUserId: "",
        current: 1,
        size: 10,
        parentId: "",
        cardNum: "",
        logisticsOrderNo: "",
        startReceiveTime: "",
        endReceiveTime: "",
        status: "",
        faceValue: "",
        cardType: "",
        cardId: "",
        cardOrigin: "",
        limitation: "",
        shareStatus: "",
        startCardNum: "",
        endCardNum: "",
      },
      // 状态:0=未使用,1=已发货,2=已使用,3=申请退款,4=已退款
      statusList: [
        {
          value: "-2",
          label: "被删除"
        },
        {
          value: "-1",
          label: "未开通 未使用"
        },
        {
          value: "0",
          label: "已开通 未使用"
        },
        {
          value: "1",
          label: "已发货"
        },
        {
          value: "2",
          label: "已使用"
        },
        {
          value: "3",
          label: "申请退款"
        },
        {
          value: "4",
          label: "已退款"
        }
      ],
      rows: [],
      cardNameList: [],
      value2: [],
      openDuration: ""
    }
  },
  filters: {
    limitationF(value) {
      //卡属性:0=普通型消费卡,1=限制型消费卡
      switch (value) {
        case "0":
          return '普通型消费卡'
        case "1":
          return '限制型消费卡'
      }
    },
    statusF(value) {
      // 状态:0=未使用,1=已发货,2=已使用,3=申请退款,4=已退款
      switch (value) {
        case "-2":
          return '被删除'
        case "-1":
          return '未开通 未使用'
        case "0":
          return '已开通 未使用'
        case "1":
          return '已发货'
        case "2":
          return '已使用'
        case "3":
          return '申请退款'
        case "4":
          return '已退款'
      }
    },

    cardOriginF(value) {
      // 来源:1=平台购买,2=批量购买
      switch (value) {
        case "1":
          return '平台购买'
        case "2":
          return '批量购买'
      }
    },
    formatDate(value) {
      return sd.format(+value)
    },
    cardTypeF(value) {
      switch (value) {
        case "1":
          return '电子卡'
        case "2":
          return '实体卡'
      }
    },
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    async exportXiTunBaoCardItemList() {
      this.loadingMsg = "文件导出中..."
      this.loading = true;
      try {
        // 调用 API 获取文件流
        const response = await exportXiTunBaoCardItemListApi(this.params);

        // 生成 Blob 对象
        // const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const blob = new Blob([response], {type: 'application/octet-stream'});
        // 创建一个临时的下载链接
        const url = window.URL.createObjectURL(blob);

        // 创建下载链接并触发点击下载
        const a = document.createElement('a');
        a.href = url;
        a.download = `${sd.format(new Date())}.xlsx`;  // 设置下载文件名

        // 将元素添加到页面
        document.body.appendChild(a);

        // 程序化点击链接
        a.click();

        // 清理 URL 和 DOM 元素
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);

        // 显示下载成功提示
        this.$message({
          message: '文件下载成功',
          type: 'success'
        });
      } catch (error) {
        // 处理错误情况
        console.error("文件下载失败:", error);
        this.$message({
          message: '文件下载失败',
          type: 'error'
        });
      } finally {
        // 结束加载状态
        this.loading = false;
      }
      /*const response = await exportXiTunBaoCardItemListApi(this.params)
      console.log(response)
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      // const blob = new Blob([response], {type: 'application/octet-stream'});
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${sd.format(new Date())}.xlsx`; // 设置下载的文件名，根据需要修改
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      this.$message({
        message: '文件下载成功',
        type: 'success'
      });
      this.loading = false;*/
    },
    editRemark(item) {
      this.$prompt('请输入修改的备注', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: item.remark
      }).then(async ({value}) => {
        await editKeyRemarkApi({
          id: item.id,
          remark: value
        })
        this.$message({
          type: 'success',
          message: '操作成功'
        });
        this.getCardItemList();
      }).catch(() => {

      });
    },
    editlogisticsOrderNo(item) {
      this.$prompt('请输入物流单号', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: item.logisticsOrderNo
      }).then(async ({value}) => {
        await editLogisticsOrderNoApi({
          id: item.id,
          logisticsOrderNo: value
        })
        this.$message({
          type: 'success',
          message: '操作成功'
        });
        await this.getCardItemList();
      }).catch(() => {

      });
    },
    bactchRemark() {
      this.$prompt('请输入修改的备注', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(async ({value}) => {

        for (let i = 0; i < this.multipleSelection.length; i++) {
          let item = this.multipleSelection[i]
          await editKeyRemarkApi({
            id: item.id,
            remark: value
          })
        }
        this.$message({
          type: 'success',
          message: '操作成功'
        });
        this.getCardItemList();
      }).catch(() => {

      });
    },
    batchStatus() {
      this.cdKeyStatus = true
    },
    resetListParams() {
      this.params = {
        current: 1,
        size: 10,
        parentId: this.item.mdlOrder,
        cardNum: "",
        logisticsOrderNo: "",
        startReceiveTime: "",
        endReceiveTime: "",
        status: "",
        faceValue: "",
        cardType: "",
        cardId: "",
        cardOrigin: "",
        limitation: "",
        shareStatus: "",
        startCardNum: "",
        endCardNum: "",
      }
      this.value2 = []
      this.getCardItemList();
    },
    initList(item) {
      this.item = item;
      this.params.parentId = item.mdlOrder;
      this.getCardItemList();
    },
    async getCardItemList() {
      this.loadingMsg = "列表加载中...";
      this.loading = true;
      this.params.startReceiveTime = ""
      this.params.endReceiveTime = ""
      this.params.startReceiveTime = this.value2.length > 0 ? `${+this.value2[0]}`.slice(0, 10) : ""
      this.params.endReceiveTime = this.value2.length > 0 ? `${+this.value2[1]}`.slice(0, 10) : ""
      let {data} = await getCardUserItemListApi(this.params);
      this.rows = data.rows
      this.size = data.size
      this.current = data.current
      this.total = data.total
      this.loading = false;
    },
    /**
     * 分页大小切换时触发
     * @param val
     */
    handleSizeChange(val) {
      this.params.size = val;
      this.getCardItemList();
    },
    /**
     * 页码切换时触发
     * @param val
     */
    handleCurrentChange(val) {
      this.params.current = val;
      this.getCardItemList();
    },
    async initCardNameList() {
      let {data} = await getXiTunBaoCardNameListApi();
      this.cardNameList = data
    },
    // 点击按钮时触发的方法，用于确认操作
    confirmEditcdKeyStatus(status) {
      // this.cdKeyStatus = true;
      if (status === 0 && !this.openDuration) {
        this.$message({
          message: '请输入开通时长',
          type: 'error'
        });
        return;
      }
      // 通过 MessageBox.confirm 方法显示二次确认对话框
      this.$confirm('确定要执行此操作吗？', '提示', {
        confirmButtonText: '确定', // 确定按钮的文本
        cancelButtonText: '取消', // 取消按钮的文本
        type: 'warning' // 消息框的类型，警告类型
      }).then(() => {
        // 用户点击了确定按钮，执行操作
        this.editcdKeyStatus(this.cardData, status);
      }).catch(() => {
        // 用户点击了取消按钮，关闭弹窗
        this.cdKeyStatus = false;
      });
      this.cdKeyStatus = false;
    },
    /**
     * 修改卡的状态
     */
    async editcdKeyStatus(item, status) {
      //  item.mdlOrder 为空 换成cardNum或者id都可以
      let card = {
        ...item,
        status: status,
        openDuration: this.openDuration
      };
      await editCardStatusApi(card);
      this.openDuration = "";
      this.getCardItemList();
      this.initCardNameList();
    }
  },
  created() {
    this.getCardItemList();
    this.initCardNameList();
  }
}
</script>